import React, { useEffect, useMemo, useState } from "react";
import { InputNumber, Input, Button, Typography, Form, Collapse, Switch, App } from "antd";
import useServerData from "../../../jotai/serverData";
import { DownloadOutlined } from "@ant-design/icons";
import { getInfoButton } from "../../../utils/utils";
import { useParams } from "react-router-dom";
import { FileData, GlobalsXML } from "../../../utils/types";

const { Panel } = Collapse;

interface VariableDefinition {
  name: string;
  type: "integer" | "float" | "string";
  default: number | string;
  unit: string;
  description: string;
  category: string;
  isBoolean?: boolean;
}

// Metadata for variables
const variableMetadata: { [key: string]: Omit<VariableDefinition, "name"> } = {
  AnimalMaxCount: {
    type: "integer",
    default: 200,
    unit: "-",
    description: "Maximal limit of spawned animals (not ambient) across all zones in map.",
    category: "Spawning",
  },
  CleanupAvoidance: {
    type: "integer",
    default: 100,
    unit: "m",
    description: "Distance from player required for item deletion.",
    category: "Cleanup",
  },
  CleanupLifetimeDeadAnimal: {
    type: "integer",
    default: 1200,
    unit: "sec",
    description: "Default lifetime for dead animals.",
    category: "Cleanup",
  },
  CleanupLifetimeDeadInfected: {
    type: "integer",
    default: 330,
    unit: "sec",
    description: "Default lifetime for dead infected.",
    category: "Cleanup",
  },
  CleanupLifetimeDeadPlayer: {
    type: "integer",
    default: 3600,
    unit: "sec",
    description: "Default lifetime for dead player.",
    category: "Cleanup",
  },
  CleanupLifetimeDefault: {
    type: "integer",
    default: 45,
    unit: "sec",
    description: "Default lifetime for entities with no specific economy setup, but damage >= 1.0 (i.e., dead).",
    category: "Cleanup",
  },
  CleanupLifetimeLimit: {
    type: "integer",
    default: 50,
    unit: "-",
    description: "How many items can be deleted at once during standard cleanup.",
    category: "Cleanup",
  },
  CleanupLifetimeRuined: {
    type: "integer",
    default: 330,
    unit: "sec",
    description: "Default lifetime for ruined loot.",
    category: "Cleanup",
  },
  FlagRefreshFrequency: {
    type: "integer",
    default: 432000,
    unit: "sec",
    description: "Items' lifetime will be refreshed with this frequency.",
    category: "Flags",
  },
  FlagRefreshMaxDuration: {
    type: "integer",
    default: 3456000,
    unit: "sec",
    description: "How long the flag will be refreshing items.",
    category: "Flags",
  },
  IdleModeCountdown: {
    type: "integer",
    default: 60,
    unit: "sec",
    description: "Activate economy idle mode on empty server after given time.",
    category: "Idle Mode",
  },
  IdleModeStartup: {
    type: "integer",
    default: 1,
    unit: "-",
    description:
      "Set to 0 to disable idle mode on server startup (will still switch on later if IdleModeCountdown is not 0).",
    category: "Idle Mode",
    isBoolean: true,
  },
  InitialSpawn: {
    type: "integer",
    default: 100,
    unit: "%",
    description: "Percentage of loot to spawn on server initial start (without storage).",
    category: "Loot",
  },
  LootProxyPlacement: {
    type: "integer",
    default: 1,
    unit: "-",
    description: "Allow dispatch containers to receive the loot.",
    category: "Loot",
    isBoolean: true,
  },
  RespawnAttempt: {
    type: "integer",
    default: 2,
    unit: "-",
    description: "How many attempts are performed during single item respawn.",
    category: "Respawning",
  },
  RespawnLimit: {
    type: "integer",
    default: 20,
    unit: "-",
    description: "How many items of one type can be spawned at once.",
    category: "Respawning",
  },
  RespawnTypes: {
    type: "integer",
    default: 12,
    unit: "-",
    description: "How many different types can be respawned at once.",
    category: "Respawning",
  },
  RestartSpawn: {
    type: "integer",
    default: 0,
    unit: "%",
    description: "Percentage of loot to respawn during restart to nominal.",
    category: "Loot",
  },
  SpawnInitial: {
    type: "integer",
    default: 1200,
    unit: "-",
    description: "How many initial tests are allowed for item spawn.",
    category: "Spawning",
  },
  TimeHopping: {
    type: "integer",
    default: 60,
    unit: "sec",
    description: "Penalty time for server hoppers.",
    category: "Time Settings",
  },
  TimeLogin: {
    type: "integer",
    default: 15,
    unit: "sec",
    description: "Default login time (max value 65536).",
    category: "Time Settings",
  },
  TimeLogout: {
    type: "integer",
    default: 15,
    unit: "sec",
    description: "Default logout time (max value 65536).",
    category: "Time Settings",
  },
  TimePenalty: {
    type: "integer",
    default: 20,
    unit: "sec",
    description: "Penalty time for player that is still in play session.",
    category: "Time Settings",
  },
  ZombieMaxCount: {
    type: "integer",
    default: 1000,
    unit: "-",
    description: "Maximal limit of spawned zombies across all zones in map.",
    category: "Spawning",
  },
  ZoneSpawnDist: {
    type: "integer",
    default: 300,
    unit: "m",
    description: "Distance to invoke infected spawn in nearby zone (dynamic infected).",
    category: "Spawning",
  },
  WorldWetTempUpdate: {
    type: "integer",
    default: 1,
    unit: "-",
    description: "Allow update of wetness and temperature values on all items in the world.",
    category: "World Settings",
    isBoolean: true,
  },
  FoodDecay: {
    type: "integer",
    default: 1,
    unit: "-",
    description: "Allow decay on food (requires WorldWetTempUpdate set to 1).",
    category: "World Settings",
    isBoolean: true,
  },
  LootDamageMin: {
    type: "float",
    default: 0,
    unit: "% (0..1)",
    description: "Minimum applied damage to any item spawned through the CE.",
    category: "Loot",
  },
  LootDamageMax: {
    type: "float",
    default: 0,
    unit: "% (0..1)",
    description: "Maximum applied damage to any item spawned through the CE.",
    category: "Loot",
  },
  LootSpawnAvoidance: {
    type: "integer",
    default: 50,
    unit: "m",
    description: "Controlling how far away a player should be from a loot group for loot to spawn inside of it.",
    category: "Loot",
  },
};

export default function GlobalSettingsEditor(props: FileData<GlobalsXML>) {
  const { serverData, downloadObjectAsXMLFile } = useServerData();
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const { type = "", modORMission: mission = "", fileName = "" } = useParams();
  const currentData = useMemo(() => (serverData.missions && serverData.missions[mission]) || {}, [serverData, mission]);

  const [variables, setVariables] = useState<VariableDefinition[]>([]);
  const [variablesByCategory, setVariablesByCategory] = useState<{ [category: string]: VariableDefinition[] }>({});

  useEffect(() => {
    if (currentData["globals.xml"]) {
      const data = props.variables.var;
      const initialValues: any = {};
      const originalValuesCopy: any = {};

      const vars: VariableDefinition[] = data.map((item) => {
        const name = item["@_name"];
        const typeCode = item["@_type"];
        let type: "integer" | "float" | "string";
        switch (typeCode) {
          case "0":
            type = "integer";
            break;
          case "1":
            type = "float";
            break;
          case "2":
            type = "string";
            break;
          default:
            type = "string";
        }

        const metadata = variableMetadata[name] || {};
        const isBoolean = metadata.isBoolean || false;

        let value;
        if (typeCode === "0") {
          value = item["@_value"];
          if (isBoolean) {
            value = value === "1";
          }
        } else if (typeCode === "1") {
          value = item["@_value"];
        } else {
          value = item["@_value"];
        }
        initialValues[name] = value;
        originalValuesCopy[name] = value; // Store original values

        return {
          name,
          type,
          default: metadata.default ?? value,
          unit: metadata.unit ?? "",
          description: metadata.description ?? "",
          category: metadata.category ?? "Others",
          isBoolean,
        };
      });

      form.setFieldsValue(initialValues);

      const varsByCategory = vars.reduce((acc, variable) => {
        if (!acc[variable.category]) {
          acc[variable.category] = [];
        }
        acc[variable.category].push(variable);
        return acc;
      }, {} as { [category: string]: VariableDefinition[] });

      setVariables(vars);
      setVariablesByCategory(varsByCategory);
    }
  }, [currentData, form, props]);

  const onFinish = (values: any) => {
    const updatedVariables = variables.map((variable) => {
      let value = values[variable.name];

      // Handle undefined values or missing fields in the form
      if (value === undefined || value === null) {
        value = variable.default; // Fall back to the default value if no value is present
      }

      // Convert boolean-like values for variables marked as isBoolean
      if (variable.isBoolean) {
        value = value ? "1" : "0";
      }

      // Safeguard against unexpected data types before calling toString
      return {
        "@_name": variable.name,
        "@_type": variable.type === "integer" ? "0" : variable.type === "float" ? "1" : "2",
        "@_value": value.toString(), // Ensure value is safely converted to a string
      };
    });

    const updatedData = { variables: { var: updatedVariables } };

    if (updatedVariables.length > 0) {
      downloadObjectAsXMLFile(updatedData, props.fullPath, `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>`);
      message.success("Configuration saved and downloaded");
    } else {
      message.error("No valid changes detected.");
    }
  };

  if (!currentData["globals.xml"]) {
    return (
      <Typography.Text>
        Please upload <Typography.Text code>globals.xml</Typography.Text> file from the menu to edit the configuration.
      </Typography.Text>
    );
  }

  const getLabel = (title: string, description: string, unit: string, defaultValue: number | string) => (
    <span>
      {title}{" "}
      {getInfoButton(
        <div>
          <p>{description}</p>
          <p>
            <strong>Default:</strong> {defaultValue} {unit}
          </p>
        </div>
      )}
    </span>
  );

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Button type="primary" htmlType="submit" icon={<DownloadOutlined />} style={{ marginBottom: 16 }}>
        Download
      </Button>
      <Collapse defaultActiveKey={Object.keys(variablesByCategory)}>
        {Object.keys(variablesByCategory).map((category) => (
          <Panel header={category} key={category}>
            {variablesByCategory[category].map((variable) => {
              let inputField;
              switch (variable.type) {
                case "integer":
                  if (variable.isBoolean) {
                    inputField = (
                      <Form.Item
                        key={variable.name}
                        name={variable.name}
                        label={getLabel(variable.name, variable.description, variable.unit, variable.default)}
                        valuePropName="checked"
                      >
                        <Switch checkedChildren="Enabled" unCheckedChildren="Disabled" />
                      </Form.Item>
                    );
                  } else {
                    inputField = (
                      <Form.Item
                        key={variable.name}
                        name={variable.name}
                        label={getLabel(variable.name, variable.description, variable.unit, variable.default)}
                        extra={variable.unit ? `Unit: ${variable.unit}` : undefined}
                      >
                        <InputNumber style={{ width: "100%" }} />
                      </Form.Item>
                    );
                  }
                  break;
                case "float":
                  inputField = (
                    <Form.Item
                      key={variable.name}
                      name={variable.name}
                      label={getLabel(variable.name, variable.description, variable.unit, variable.default)}
                      extra={variable.unit ? `Unit: ${variable.unit}` : undefined}
                    >
                      <InputNumber style={{ width: "100%" }} step={0.1} />
                    </Form.Item>
                  );
                  break;
                case "string":
                  inputField = (
                    <Form.Item
                      key={variable.name}
                      name={variable.name}
                      label={getLabel(variable.name, variable.description, variable.unit, variable.default)}
                    >
                      <Input />
                    </Form.Item>
                  );
                  break;
                default:
                  inputField = null;
              }
              return inputField;
            })}
          </Panel>
        ))}
      </Collapse>
    </Form>
  );
}
