import CryptoJS from "crypto-js";

export default class CryptoManager {
  private static key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_SECRET || "");
  private static ivLength = 16; // Length of IV for AES is 16 bytes

  static encrypt(data: string) {
    const iv = CryptoJS.lib.WordArray.random(this.ivLength);
    const cipher = CryptoJS.AES.encrypt(data, this.key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    const ivHex = iv.toString(CryptoJS.enc.Hex);
    const encrypted = cipher.toString();
    return `${ivHex}:${encrypted}`; // Format: IV:Ciphertext
  }

  static decrypt(data: string) {
    const parts = data.split(":");
    const iv = CryptoJS.enc.Hex.parse(parts[0]);
    const encryptedText = parts[1];
    const cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(encryptedText),
    });
    const decrypted = CryptoJS.AES.decrypt(cipherParams, this.key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
