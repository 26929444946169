import { App, Button, Flex, Input, List, Popover, Select, Space, theme, Typography } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { MdQuestionMark, MdSearch } from "react-icons/md";
import useServerData from "../../../jotai/serverData";
import { FaDownload, FaPlus, FaTrash } from "react-icons/fa6";
import { getDecoratedText } from "../../../utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import { FileData, IgnoreListXML } from "../../../utils/types";

export default function IgnoreListEditor(props: FileData<IgnoreListXML>) {
  const { serverData, downloadObjectAsXMLFile } = useServerData();
  const {
    token: { padding },
  } = theme.useToken();
  const { message } = App.useApp();
  const [pickedNewValue, setPickedNewValue] = useState<string | undefined>();
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const { type = "", modORMission: mission = "", fileName = "" } = useParams();
  const currentData = useMemo(() => (serverData.missions && serverData.missions[mission]) || {}, [serverData, mission]);
  const AllTypes = currentData["types.xml"]?.types.type;
  const ignoreList = props.ignore.type;

  const [updatedIgnoreList, setUpdatedIgnoreList] = useState(ignoreList);
  const [renderedIgnoreList, setRenderedIgnoreList] = useState(ignoreList);

  useEffect(() => {
    setUpdatedIgnoreList(props.ignore.type);
    setRenderedIgnoreList(props.ignore.type);
  }, [props]);

  function handleDelete(itemName: string) {
    setUpdatedIgnoreList((prev) => prev?.filter(({ "@_name": name }) => name !== itemName));
    setRenderedIgnoreList((prev) => prev?.filter(({ "@_name": name }) => name !== itemName));
    message.warning(`${itemName} Was deleted from the ignore list`);
  }

  function addNewItem() {
    if (pickedNewValue) {
      const newList = [...(updatedIgnoreList || []), { "@_name": pickedNewValue }];
      setUpdatedIgnoreList(newList);
      filter(newList);
      setPickedNewValue(undefined);
      message.success(`${pickedNewValue} Was added to the ignore list`);
    }
  }

  function filter(updatedList = updatedIgnoreList) {
    setRenderedIgnoreList(
      updatedList?.filter(({ "@_name": name }) => name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
    );
  }

  function handleDownload() {
    if (updatedIgnoreList) {
      downloadObjectAsXMLFile(
        { ignore: { type: updatedIgnoreList } },
        props.fullPath,
        `<?xml version="1.0" encoding="UTF-8" standalone="yes" ?>`
      );
    }
  }

  useEffect(() => {
    filter();
  }, [searchValue]);

  return (
    <>
      <Flex vertical gap={padding} style={{ height: "100%" }}>
        <Space>
          <Space align="start">
            <Button icon={<MdQuestionMark />} size="small" shape="circle" />
            <Typography.Text style={{ whiteSpace: "pre" }}>
              {`The DayZ server cfgignorelist.xml file is used to specify which items will not be spawned and loaded into the world for players to find and use.`}
            </Typography.Text>
          </Space>
        </Space>
        <List
          header={
            <Space direction="vertical">
              <Space align="center" style={{ gap: padding * 2 }}>
                <Button onClick={handleDownload} type="primary" htmlType="submit" icon={<FaDownload />}>
                  Download
                </Button>
                <Space>
                  <Typography>Add Item To Ignore</Typography>
                  <Popover
                    content={
                      !AllTypes?.length &&
                      getDecoratedText([
                        "Upload ",
                        { value: "types.xml", type: "code" },
                        " at ",
                        { type: "link", value: "Loot", onClick: () => navigate(`/${mission}/types.xml`) },
                        " this value",
                      ])
                    }
                  >
                    <Space>
                      <Select
                        allowClear
                        onClear={() => setPickedNewValue(undefined)}
                        value={pickedNewValue}
                        onSelect={(value) => setPickedNewValue(value)}
                        showSearch
                        placeholder="Pick item to add"
                        style={{ width: 200 }}
                        disabled={!AllTypes?.length}
                        options={AllTypes?.filter(
                          ({ "@_name": name }) =>
                            !updatedIgnoreList?.some(({ "@_name": ignoreName }) => ignoreName === name)
                        ).map(({ "@_name": name }) => ({ value: name, label: name }))}
                      />
                      <Button
                        disabled={!AllTypes?.length || !pickedNewValue}
                        type="primary"
                        icon={<FaPlus />}
                        onClick={addNewItem}
                      >
                        Add
                      </Button>
                    </Space>
                  </Popover>
                </Space>
              </Space>
              <Input
                allowClear
                onChange={(e) => setSearchValue(e.target.value)}
                prefix={<MdSearch />}
                placeholder="Search item here"
              />
            </Space>
          }
          bordered
          style={{ height: "100%", overflowY: "auto" }}
          dataSource={renderedIgnoreList || []}
          renderItem={(item) => (
            <List.Item
              extra={
                <Button icon={<FaTrash />} danger onClick={() => handleDelete(item["@_name"])}>
                  Delete
                </Button>
              }
            >
              <Space>
                {updatedIgnoreList?.some(({ "@_name": name }) => name === item["@_name"]) &&
                  !ignoreList?.some(({ "@_name": name }) => name === item["@_name"]) && (
                    <Typography.Text type="success" strong>
                      NEW!
                    </Typography.Text>
                  )}
                <Typography>{item["@_name"]}</Typography>
              </Space>
            </List.Item>
          )}
        />
      </Flex>
    </>
  );
}
