import { Button, Divider, Flex, InputNumber, Popover, Select, Slider, Space, Table, theme, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { MdQuestionMark } from "react-icons/md";
import useServerData from "../../../jotai/serverData";
import { getDecoratedText, getInfoButton, MINUTE } from "../../../utils/utils";
import type { TableColumnType } from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";
import FilterDropdown from "./FilterDropdown";
import { AiFillFilter } from "react-icons/ai";
import { FaDownload } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { FileData, TypeXML } from "../../../utils/types";
import Increaser from "./Increaser";

export default function LootEditor(props: FileData<TypeXML>) {
  const {
    types: { type: loot },
  } = props;
  const {
    token: { colorError },
  } = theme.useToken();
  const [loading, setLoading] = useState(false);
  const { serverData, downloadObjectAsXMLFile } = useServerData();
  const navigate = useNavigate();
  const missions = useMemo(() => serverData.missions || {}, [serverData]);
  const { modORMission: mission = "" } = useParams();
  const currentTemplate = useMemo(() => serverData["serverDZ.cfg"]?.template, [serverData]);
  const activeMission = useMemo(
    () => missions[mission] || missions[currentTemplate || ""] || {},
    [missions, mission, currentTemplate]
  );
  const cfglimitsdefinitionXML = useMemo(() => activeMission["cfglimitsdefinition.xml"], [activeMission]);
  const {
    categories: { category: categories },
    usageflags: { usage: usages },
    valueflags: { value: values },
  } = useMemo(
    () => cfglimitsdefinitionXML?.lists || { categories: {}, usageflags: {}, valueflags: {} },
    [cfglimitsdefinitionXML]
  );
  const ignoreList = activeMission["cfgignorelist.xml"]?.ignore.type;

  const [dataSource, setDataSource] = useState([loot].flat());
  const [filters, setFilters] = useState<{ [filterKey: string]: any }>({});
  const filteredDataSource = useMemo(() => getFilteredDataSource(), [dataSource, filters]);

  useEffect(() => {
    setDataSource([loot].flat());
  }, [loot]);

  const handleSearch = (selectedKeys: any[], confirm: FilterDropdownProps["confirm"], filterKey: string) => {
    confirm();
    setFilters((prev) => ({ ...prev, [filterKey]: selectedKeys }));
  };

  const handleReset = (confirm: FilterDropdownProps["confirm"], clearFilters: () => void, filterKey: string) => {
    clearFilters();
    let updatedFilters = { ...filters };
    delete updatedFilters[filterKey];
    setFilters(updatedFilters);
    confirm();
  };

  const categoriesOptions = useMemo(
    () =>
      activeMission["cfglimitsdefinition.xml"]
        ? categories?.map((record: any) => ({
            title: record["@_name"],
            value: record["@_name"],
          }))
        : undefined,
    [activeMission, categories]
  );

  const usageOptions = useMemo(
    () =>
      activeMission["cfglimitsdefinition.xml"]
        ? usages?.map((record: any) => ({
            title: record["@_name"],
            value: record["@_name"],
          }))
        : undefined,
    [activeMission, usages]
  );

  const valueOptions = useMemo(
    () =>
      activeMission["cfglimitsdefinition.xml"]
        ? values?.map((record: any) => ({
            title: record["@_name"],
            value: record["@_name"],
          }))
        : undefined,
    [activeMission, values]
  );

  const columnsData = useMemo(
    (): (Omit<TableColumnType<(typeof loot)[number]>, "title"> & { description?: React.ReactNode; title: any })[] => [
      {
        dataIndex: "@_name",
        title: "Name",
        render: (name, record) => (
          <Flex align="center">
            <Popover content={name}>
              <Typography.Text ellipsis style={{ width: "100%" }}>
                {name}
              </Typography.Text>
            </Popover>
            {ignoreList?.some(({ "@_name": name }) => record["@_name"] === name) && (
              <Popover
                content={getDecoratedText([
                  "This item is disabled via the ",
                  {
                    type: "link",
                    value: "Items Ignore",
                    onClick: () => redirectToMenuItem("missions/cfgignorelist.xml"),
                  },
                  " List",
                  <br />,
                  "You may also find it here: ",
                  { type: "code", value: "cfgignorelist.xml" },
                ])}
              >
                <Button style={{ backgroundColor: colorError }} icon={<MdQuestionMark />} size="small" shape="circle" />
              </Popover>
            )}
          </Flex>
        ),
        filterDropdown: (props) =>
          FilterDropdown.Strings({
            value: props.selectedKeys[0],
            placeholder: "Search name",
            onConfirm: ({ selectedKeys, confirm }) => handleSearch(selectedKeys, confirm, "@_name"),
            onReset: ({ confirm, clearFilters }) => clearFilters && handleReset(confirm, clearFilters, "@_name"),
            ...props,
          }),
        filterIcon: (filtered: boolean) => (
          <AiFillFilter style={{ color: filtered ? "#1677ff" : undefined, fontSize: 15 }} />
        ),
        sorter: (a, b) => a["@_name"].localeCompare(b["@_name"]),
      },
      {
        dataIndex: "category",
        title: "Category",
        render: (value, record) => (
          <Popover
            content={
              !usageOptions?.length &&
              getDecoratedText([
                "Upload ",
                { type: "code", value: "cfglimitsdefinition.xml" },
                " at ",
                { type: "link", value: "Item Limits", onClick: redirectToItemLimits },
                " to edit this value",
              ])
            }
          >
            <Select
              disabled={
                !categoriesOptions?.length || ignoreList?.some(({ "@_name": name }) => record["@_name"] === name)
              }
              style={{ minWidth: 150 }}
              options={categoriesOptions}
              value={value && value["@_name"]}
              onChange={(value) => updateDataSource({ name: value }, "category", record["@_name"])}
            />
          </Popover>
        ),
        filters: categoriesOptions?.map((category: any) => ({ text: category.title, value: category.value })),
        onFilter: (value, record) => record && record["category"] && record["category"]["@_name"] === value,
      },
      {
        dataIndex: "cost",
        title: "Spawn Chance",
        description: "The spawn chance.",
        filterDropdown: (props) =>
          FilterDropdown.Numbers({
            min: Math.min(...dataSource.map(({ cost }) => +cost)) || 0,
            max: Math.max(...dataSource.map(({ cost }) => +cost)),
            onConfirm: ({ selectedKeys, confirm }) => handleSearch(selectedKeys, confirm, "cost"),
            onReset: ({ confirm, clearFilters }) => clearFilters && handleReset(confirm, clearFilters, "cost"),
            ...props,
          }),
        filterIcon: (filtered: boolean) => (
          <AiFillFilter style={{ color: filtered ? "#1677ff" : undefined, fontSize: 15 }} />
        ),
        render: (value, record) => (
          <InputNumber
            disabled={ignoreList?.some(({ "@_name": name }) => record["@_name"] === name)}
            value={value}
            onChange={(value) => updateDataSource(value, "cost", record["@_name"])}
          />
        ),
      },
      {
        dataIndex: "lifetime",
        title: "Life Time",
        description: "Once this item spawns how long (seconds) it will stay in place if no one picks it up.",
        filterDropdown: (props) =>
          FilterDropdown.Numbers({
            min: Math.min(...dataSource.map(({ lifetime }) => (lifetime * 1000) / MINUTE)) || 0,
            max: Math.max(...dataSource.map(({ lifetime }) => (lifetime * 1000) / MINUTE)),
            onConfirm: ({ selectedKeys, confirm }) => handleSearch(selectedKeys, confirm, "lifetime"),
            onReset: ({ confirm, clearFilters }) => clearFilters && handleReset(confirm, clearFilters, "lifetime"),
            ...props,
          }),
        filterIcon: (filtered: boolean) => (
          <AiFillFilter style={{ color: filtered ? "#1677ff" : undefined, fontSize: 15 }} />
        ),
        render: (value: number, record) => (
          <Space>
            <InputNumber
              disabled={ignoreList?.some(({ "@_name": name }) => record["@_name"] === name)}
              step={100}
              value={value}
              onChange={(value) => updateDataSource(Math.ceil(value || 0), "lifetime", record["@_name"])}
            />
            Seconds
          </Space>
        ),
      },
      {
        title: "Amount",
        description: "Amount for this item to spawn.",
        filterDropdown: (props) =>
          FilterDropdown.Numbers({
            min: Math.min(...dataSource.map(({ min }) => min)) || 0,
            max: Math.max(...dataSource.map(({ nominal }) => nominal)),
            onConfirm: ({ selectedKeys, confirm }) => handleSearch(selectedKeys, confirm, "amount"),
            onReset: ({ confirm, clearFilters }) => clearFilters && handleReset(confirm, clearFilters, "amount"),
            ...props,
          }),
        filterIcon: (filtered: boolean) => (
          <AiFillFilter style={{ color: filtered ? "#1677ff" : undefined, fontSize: 15 }} />
        ),
        render: (_, record) => (
          <Slider
            disabled={ignoreList?.some(({ "@_name": name }) => record["@_name"] === name)}
            range
            value={[record["min"] || 0, record["nominal"] || 0]}
            max={(record["nominal"] || 0) * 2 || 100}
            onChange={(value) => updateDataSource(value, "amount", record["@_name"])}
          />
        ),
      },
      {
        title: "Item Quantity",
        description: "Maximum amount within the item (e.g Does a water bottle has 50% fill?)",
        filterDropdown: (props) =>
          FilterDropdown.Numbers({
            min: 0,
            max: Math.max(...dataSource.map(({ quantmax }) => quantmax)),
            onConfirm: ({ selectedKeys, confirm }) => handleSearch(selectedKeys, confirm, "itemQuantity"),
            onReset: ({ confirm, clearFilters }) => clearFilters && handleReset(confirm, clearFilters, "itemQuantity"),
            ...props,
          }),
        filterIcon: (filtered: boolean) => (
          <AiFillFilter style={{ color: filtered ? "#1677ff" : undefined, fontSize: 15 }} />
        ),
        render: (_, record) =>
          record["quantmax"] > 0 ? (
            <Slider
              disabled={ignoreList?.some(({ "@_name": name }) => record["@_name"] === name)}
              range
              value={[record["quantmin"], record["quantmax"]]}
              max={record["quantmax"] * 2}
              onChange={(value) => updateDataSource(value, "itemQuantity", record["@_name"])}
            />
          ) : (
            "No Quantity Available"
          ),
      },
      {
        dataIndex: "restock",
        title: "Restock",
        filterDropdown: (props) =>
          FilterDropdown.Numbers({
            min: Math.min(...dataSource.map(({ restock }) => +restock)) || 0,
            max: Math.max(...dataSource.map(({ restock }) => +restock)),
            onConfirm: ({ selectedKeys, confirm }) => handleSearch(selectedKeys, confirm, "restock"),
            onReset: ({ confirm, clearFilters }) => clearFilters && handleReset(confirm, clearFilters, "restock"),
            ...props,
          }),
        filterIcon: (filtered: boolean) => (
          <AiFillFilter style={{ color: filtered ? "#1677ff" : undefined, fontSize: 15 }} />
        ),
        description: "Time until this item can spawn in again.",
        render: (value, record) => (
          <InputNumber
            disabled={ignoreList?.some(({ "@_name": name }) => record["@_name"] === name)}
            defaultValue={value}
            onChange={(value) => updateDataSource(value, "restock", record["@_name"])}
          />
        ),
      },
      {
        dataIndex: "usage",
        title: "Usage",
        description: getDecoratedText([
          "Area for where the item will spawn e.g farm. You can have up to 4.",
          <br />,
          "view at ",
          { type: "link", value: "Item Limits", onClick: redirectToItemLimits },
          " or in ",
          { type: "code", value: "cfglimitsdefinition.xml" },
        ]),
        render: (value, record) => (
          <Popover
            content={
              !usageOptions?.length &&
              getDecoratedText([
                "Upload ",
                { type: "code", value: "cfglimitsdefinition.xml" },
                " at ",
                { type: "link", value: "Item Limits", onClick: redirectToItemLimits },
                " to edit this value",
              ])
            }
          >
            <Select
              disabled={!usageOptions?.length || ignoreList?.some(({ "@_name": name }) => record["@_name"] === name)}
              style={{ minWidth: 100 }}
              options={usageOptions}
              value={value && value["@_name"]}
              onChange={(value) => updateDataSource({ name: value }, "usage", record["@_name"])}
            />
          </Popover>
        ),
        filters: usageOptions?.map((usage: any) => ({ text: usage.title, value: usage.value })),
        onFilter: (value, record) => record && record["usage"] && record["usage"]["@_name"] === value,
      },
      {
        dataIndex: "value",
        title: "Loot Density",
        description: getDecoratedText([
          "Item value grouping. Tier1 (Spawn zones) through to Tier4 (Tisy military).",
          <br />,
          "view at ",
          { type: "link", value: "Item Limits", onClick: redirectToItemLimits },
          " or in ",
          { type: "code", value: "cfglimitsdefinition.xml" },
        ]),
        render: (value: any, record) => {
          let usedValue = value;
          if (!(value instanceof Array)) {
            usedValue = value ? [value] : [];
          }

          return (
            <Popover
              content={
                !usageOptions?.length &&
                getDecoratedText([
                  "Upload ",
                  { type: "code", value: "cfglimitsdefinition.xml" },
                  " at ",
                  { type: "link", value: "Item Limits", onClick: redirectToItemLimits },
                  " to edit this value",
                ])
              }
            >
              <Select
                disabled={!valueOptions?.length || ignoreList?.some(({ "@_name": name }) => record["@_name"] === name)}
                onChange={(value) =>
                  updateDataSource(
                    value.map((v: any) => ({ ["@_name"]: v })),
                    "value",
                    record["@_name"]
                  )
                }
                style={{ minWidth: 100 }}
                options={valueOptions}
                mode="multiple"
                defaultValue={usedValue
                  ?.filter(({ ["@_name"]: name }: any) => valueOptions?.some(({ value }: any) => value === name))
                  .map(({ ["@_name"]: name }: any) => name)}
              />
            </Popover>
          );
        },
        filters: valueOptions?.map((value: any) => ({ text: value.title, value: value.value })),
        onFilter: (value, record) => {
          let usedValue = record["value"];
          if (!(record["value"] instanceof Array)) {
            usedValue = record["value"] ? [record["value"]] : [];
          }

          return (
            usedValue as {
              "@_name": string;
            }[]
          )?.some(({ name }: any) => name === value);
        },
      },
    ],
    [activeMission, usageOptions, categoriesOptions, colorError, dataSource, ignoreList, valueOptions]
  );

  function updateDataSource(value: any, dataIndex: string, name: string) {
    switch (dataIndex) {
      case "itemQuantity":
        setDataSource((prev) =>
          prev.map((record) =>
            record["@_name"] === name ? { ...record, quantmin: value[0], quantmax: value[1] } : record
          )
        );
        break;
      case "amount":
        setDataSource((prev) =>
          prev.map((record) => (record["@_name"] === name ? { ...record, min: value[0], nominal: value[1] } : record))
        );
        break;

      default:
        setDataSource((prev) =>
          prev.map((record) => (record["@_name"] === name ? { ...record, [dataIndex]: value } : record))
        );
        break;
    }
  }

  function updateAllValues(
    dataIndex: keyof (typeof loot)[number] | "itemQuantity" | "amount",
    amount: any,
    isPercentage: boolean
  ) {
    setLoading(true);
    const copyDS = [...getFilteredDataSource()];
    copyDS.forEach((record) => {
      switch (dataIndex) {
        case "itemQuantity":
          updateDataSource(
            isPercentage
              ? [
                  Math.ceil(record["quantmin"] * (1 + amount[0] / 100)),
                  Math.ceil(record["quantmax"] * (1 + amount[1] / 100)),
                ]
              : [Math.ceil(record["quantmin"] + amount[0]), Math.ceil(record["quantmax"] + amount[1])],
            dataIndex,
            record["@_name"]
          );
          break;
        case "amount":
          updateDataSource(
            isPercentage
              ? [Math.ceil(record["min"] * (1 + amount[0] / 100)), Math.ceil(record["nominal"] * (1 + amount[1] / 100))]
              : [Math.ceil(record["min"] + amount[0]), Math.ceil(record["nominal"] + amount[1])],
            dataIndex,
            record["@_name"]
          );
          break;
        default:
          updateDataSource(
            //@ts-ignore
            isPercentage ? Math.ceil(record[dataIndex] * (1 + amount / 100)) : record[dataIndex] + amount,
            dataIndex,
            record["@_name"]
          );
          break;
      }
    });
    setLoading(false);
  }

  function redirectToMenuItem(path: string) {
    const [type, fileName] = path.split("/");
    navigate(`/${type}${type !== "serverDZ.cfg" ? `/${currentTemplate}/${fileName}` : ""}`);
  }

  function redirectToItemLimits() {
    redirectToMenuItem("missions/cfglimitsdefinition.xml");
  }

  function getFilteredDataSource() {
    let filteredDataSource = [...dataSource];
    for (const key in filters) {
      const filterValue = filters[key];
      filteredDataSource = filteredDataSource.filter((record) => {
        switch (key) {
          case "lifetime":
            return (
              filterValue[0] <= (record["lifetime"] * MINUTE) / 1000 &&
              (record["lifetime"] * MINUTE) / 1000 <= filterValue[1]
            );
          case "itemQuantity":
            if (filterValue[0] === 0 && filterValue[1] === 0)
              return -1 === record["quantmin"] && record["quantmax"] === -1;
            else return filterValue[0] <= record["quantmin"] && record["quantmax"] <= filterValue[1];

          case "amount":
            return filterValue[0] <= record["min"] && record["nominal"] <= filterValue[1];
          case "cost":
            return filterValue[0] <= record["cost"] && record["cost"] <= filterValue[1];
          case "category":
            if (!filterValue.length) return true;
            return `${filterValue}`
              .toLowerCase()
              .includes(record && record["category"] && `${record["category"]["@_name"]}`.toLowerCase());
          default:
            //@ts-ignore
            return `${record[key]}`.toLowerCase().includes(`${filterValue}`.toLowerCase());
        }
      });
    }

    return filteredDataSource;
  }

  return (
    <>
      <Space align="start">
        <Button icon={<MdQuestionMark />} size="small" shape="circle" />
        <Typography.Text style={{ whiteSpace: "pre" }}>
          {`The DayZ server types.xml file is the main handler for loot spawning and then respawning or regenerating.\nIf you want an item to spawn in as loot and regenerate it must be in this file.`}
        </Typography.Text>
      </Space>
      <Table
        title={() => (
          <Space split={<Divider type="vertical" />} style={{ overflowX: "auto", width: "100%" }}>
            <Button
              icon={<FaDownload />}
              type="primary"
              onClick={() => downloadObjectAsXMLFile({ types: { type: dataSource } }, props.fullPath)}
            >
              Download
            </Button>
            <Increaser
              title="Spawn Chance"
              onChange={(amount, isPercentage) => updateAllValues("cost", amount, isPercentage)}
            />
            <Increaser
              title="Life Time"
              onChange={(amount, isPercentage) => updateAllValues("lifetime", amount, isPercentage)}
            />
            <Increaser
              title="Amount"
              onChange={(amount, isPercentage, range) =>
                updateAllValues(
                  "amount",
                  [range?.minKey ? amount : isPercentage ? 1 : 0, range?.maxKey ? amount : isPercentage ? 1 : 0],
                  isPercentage
                )
              }
              range={{ minKey: "min", maxKey: "nominal" }}
            />
            <Increaser
              title="Item Quantity"
              onChange={(amount, isPercentage, range) =>
                updateAllValues(
                  "itemQuantity",
                  [range?.minKey ? amount : isPercentage ? 1 : 0, range?.maxKey ? amount : isPercentage ? 1 : 0],
                  isPercentage
                )
              }
              range={{ minKey: "quantmin", maxKey: "quantmax" }}
            />
            <Increaser
              title="Restock"
              onChange={(amount, isPercentage) => updateAllValues("restock", amount, isPercentage)}
            />
          </Space>
        )}
        virtual
        scroll={{ y: 550, x: 1500 }}
        loading={loading}
        dataSource={filteredDataSource}
        columns={columnsData.map(({ title, description, ...otherProps }, i) => ({
          key: `${i}-loot-editor-column`,
          title: (
            <Space>
              {title}
              {description && getInfoButton(description)}
            </Space>
          ),
          ...otherProps,
        }))}
      />
    </>
  );
}
