import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useDataFromPathValues from "../../hooks/useDataFromPathValues";

export default function EditorRedirector() {
  const { type = "", modORMission = "", fileName = "" } = useParams();

  const data = useDataFromPathValues({ type, modORMission, fileName });
  const navigate = useNavigate();
  const UI = useMemo(() => data?.component, [data]);

  useEffect(() => {
    if (!data) {
      navigate("/");
    }
  }, [data, navigate]);

  return UI ? <UI {...data} /> : <></>;
}
