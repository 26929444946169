import "./App.css";
import { ConfigProvider, theme, App as AntdApp } from "antd";
import useDarkMode from "./jotai/darkMode";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";

function App() {
  const { darkMode } = useDarkMode();

  return (
    <ConfigProvider
      theme={{
        algorithm: darkMode ? theme.darkAlgorithm : undefined,
        cssVar: true,
      }}
    >
      <AntdApp notification={{ placement: "topRight", duration: 2.5 }}>
        <RouterProvider router={router} />
      </AntdApp>
    </ConfigProvider>
  );
}

export default App;
