import { Button, Card, Flex, Image, Input, Layout, Modal, Space, theme, Typography } from "antd";
import { Content, Footer } from "antd/es/layout/layout";
import DarkModeSwitch from "../components/DarkModeSwitch";
import { MdSearch } from "react-icons/md";
import CustomMenu from "../components/CustomMenu";
import Sider from "antd/es/layout/Sider";
import { createBrowserRouter, Outlet, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getDecoratedText, MINUTE } from "../utils/utils";
import FileReader from "../components/FilesReader/FilesReader";
import EditorRedirector from "../components/Editors/EditorRedirector";
import ScreenBlockLoader from "../components/ScreenBlockLoader";

function Root() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    token: { colorBgContainer, padding, lineType, colorSplit, lineWidth, gold6 },
  } = theme.useToken();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => setIsModalOpen(true), MINUTE * 10);
  }, []);

  return (
    <Layout
      style={{
        height: "100vh",
        maxHeight: "100vh",
        width: "100vw",
        maxWidth: "100vw",
        overflow: "hidden",
        minHeight: 500,
      }}
    >
      <ScreenBlockLoader />
      <Modal
        title={
          <Typography.Title style={{ margin: 0 }} level={3}>
            Lets talk about beers 🍺
          </Typography.Title>
        }
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={
          <Button
            style={{ backgroundColor: gold6 }}
            type="primary"
            icon="🍺"
            onClick={() => window.open("https://buymeacoffee.com/yuvalperetz", "_blank")?.focus()}
          >
            Buy me a Beer
          </Button>
        }
      >
        Do you like beers? I like beers
        <br />
        In this time of uncertainty for us developers, a beer gives a real boost to the moral.
        <br />
        Give us a beer would ya?
      </Modal>
      <Sider
        width={250}
        style={{
          backgroundColor: colorBgContainer,
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden",
          borderRight: `${lineWidth}px ${lineType} ${colorSplit}`,
        }}
      >
        <Flex vertical align="center" style={{ gap: 8, height: "100%", padding: `${padding}px 0px` }}>
          <Flex vertical justify="center" align="center" style={{ width: "100%" }}>
            <Image
              onClick={() => navigate("/")}
              preview={false}
              style={{ borderRadius: 100, height: 50, cursor: "pointer" }}
              src="../android-chrome-512x512.png"
            />
            <Typography.Title level={4} style={{ margin: 0 }}>
              DayZ Server Editor
            </Typography.Title>
          </Flex>
          <DarkModeSwitch />
          <Space>
            <Input disabled prefix={<MdSearch />} placeholder="Search in the future..." />
          </Space>
          <CustomMenu />
        </Flex>
      </Sider>
      <Layout style={{ height: "100%", minHeight: 500 }}>
        <Content style={{ padding, height: "100%" }}>
          <div style={{ overflowY: "auto" }}>
            <Card
              style={{ height: "calc(100vh - 75px)", minHeight: 423, overflowY: "auto" }}
              styles={{ body: { height: "100%" } }}
            >
              <Outlet />
            </Card>
          </div>
        </Content>
        <Footer style={{ height: 60 }}>
          <Flex align="center" justify="center" style={{ height: "100%" }}>
            <Space>
              <Button
                style={{ backgroundColor: gold6 }}
                type="primary"
                icon="🍺"
                onClick={() => window.open("https://buymeacoffee.com/yuvalperetz", "_blank")?.focus()}
              >
                Buy me a Beer
              </Button>
              {getDecoratedText.Wrapped([
                "DayZ Server Editor © 2024 Created by ",
                { value: "Yuval Peretz", type: "link", href: "https://www.linkedin.com/in/yuval-peretz-a271641b6/" },
              ])}
            </Space>
          </Flex>
        </Footer>
      </Layout>
    </Layout>
  );
}

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "",
        element: <FileReader />,
      },
      {
        path: ":type",
        loader: ({ params: { type } }) => {
          return { type };
        },
        element: <EditorRedirector />,
        children: [
          {
            path: ":modORMission",
            children: [
              {
                path: ":fileName",
                loader: ({ params: { type, modORMission, fileName } }) => {
                  return { type, modORMission, fileName };
                },
                element: <EditorRedirector />,
              },
            ],
          },
        ],
      },
    ],
  },
]);
