import { useMemo } from "react";
import { FileData } from "../utils/types";
import useServerData from "../jotai/serverData";

export default function useDataFromPathValues({ type = "", modORMission = "", fileName = "" }) {
  const { serverData } = useServerData();
  const missions = useMemo(() => serverData.missions || {}, [serverData]);
  const mods = useMemo(() => serverData.mods || {}, [serverData]);

  const data = useMemo(
    (): FileData<{}> =>
      type === "serverDZ.cfg"
        ? serverData["serverDZ.cfg"]
        : type === "mpmissions"
        ? missions[modORMission] && missions[modORMission][fileName]
        : mods[modORMission] && mods[modORMission][fileName],
    [missions, fileName, modORMission, mods, fileName, serverData]
  );

  return data;
}
