import { Button, Flex, theme, Upload } from "antd";
import { MdUpload } from "react-icons/md";
import useServerData from "../../jotai/serverData";
import News from "./News/News";
import { getDecoratedText } from "../../utils/utils";

export default function FileReader() {
  const { convertXMLToObject, parseServerDZConfig, setProcessedFiles, processedFiles } = useServerData();
  const {
    token: { padding },
  } = theme.useToken();

  return (
    <Flex vertical align="center" style={{ width: "100%", height: "100%", gap: padding }}>
      {getDecoratedText([
        { type: "strong", value: "HOW TO START?" },
        getDecoratedText.Wrapped([
          "Pick up a server's folder, for example: ",
          { value: "C:/MyServerFolder", type: "code" },
        ]),
        getDecoratedText.Wrapped([
          "This folder should include files folders like ",
          { value: "mpmissions", type: "code" },
          " or ",
          { value: "@SomeModeName", type: "code" },
        ]),
      ])}
      <Upload
        beforeUpload={(file, fileList) => {
          const listLength = fileList.filter((file) => file.name.includes(".xml") || file.name.includes(".cfg")).length;
          processedFiles.toProcess !== listLength && setProcessedFiles({ processed: 0, toProcess: listLength });
          if (file.type === "text/xml") {
            convertXMLToObject(file);
            return true;
          } else if (file.name.includes(".cfg")) {
            parseServerDZConfig(file);
            return true;
          } else return Upload.LIST_IGNORE;
        }}
        directory
        showUploadList={false}
      >
        <Button icon={<MdUpload />}>Upload Mission Directory</Button>
      </Upload>
      <News />
    </Flex>
  );
}
