import { Button, ButtonProps, Popover, Typography } from "antd";
import { DateManipulationOptions, DateUnit, FileData, ServerConfigCFG, SpecialDate, TypeXML } from "./types";
import { MdQuestionMark } from "react-icons/md";
import { isValidElement } from "react";
import ServerConfigEditor from "../components/Editors/ServerConfigEditor/ServerConfigEditor";
import LootEditor from "../components/Editors/LootEditor/LootEditor";
import EventsEditor from "../components/Editors/EventsEditor/EventsEditor";
import GlobalSettingsEditor from "../components/Editors/GlobalSettingsEditor/GlobalSettingsEditor";
import MessagesEditor from "../components/Editors/MessagesEditor/MessagesEditor";
import IgnoreListEditor from "../components/Editors/IgnoreListEditor/IgnoreListEditor";
import UnderConstructionResult from "../components/UnderConstructionResult";

export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
export const YEAR = DAY * 365;

export function manipulateDate(date: Date = new Date(), options?: DateManipulationOptions): SpecialDate {
  if (!(date instanceof Date)) date = new Date();
  const newDate = new Date(date);

  const applyOperation = (amount: number, unit: DateUnit, operation: "add" | "subtract") => {
    const multiplier = operation === "add" ? 1 : -1;
    switch (unit) {
      case "milliseconds":
        newDate.setMilliseconds(newDate.getMilliseconds() + amount * multiplier);
        break;
      case "seconds":
        newDate.setSeconds(newDate.getSeconds() + amount * multiplier);
        break;
      case "minutes":
        newDate.setMinutes(newDate.getMinutes() + amount * multiplier);
        break;
      case "hours":
        newDate.setHours(newDate.getHours() + amount * multiplier);
        break;
      case "days":
        newDate.setDate(newDate.getDate() + amount * multiplier);
        break;
      case "weeks":
        newDate.setDate(newDate.getDate() + amount * 7 * multiplier);
        break;
      case "months":
        newDate.setMonth(newDate.getMonth() + amount * multiplier);
        break;
      case "years":
        newDate.setFullYear(newDate.getFullYear() + amount * multiplier);
        break;
    }
  };

  if (options) {
    if (options.add) {
      for (const [unit, amount] of Object.entries(options.add)) {
        applyOperation(amount!, unit as DateUnit, "add");
      }
    }

    if (options.subtract) {
      for (const [unit, amount] of Object.entries(options.subtract)) {
        applyOperation(amount!, unit as DateUnit, "subtract");
      }
    }
  }

  return {
    originalDate: newDate,
    shortYear: newDate.getFullYear() % 100,
    fullYear: newDate.getFullYear(),
    month: newDate.getMonth() + 1, // Months are 0-based
    textMonth: newDate.toLocaleString("default", { month: "long" }),
    shortTextMonth: newDate.toLocaleString("default", { month: "short" }),
    day: newDate.getDate(),
    dayWith0: newDate.getDate().toString().padStart(2, "0"),
    dayOfWeek: newDate.toLocaleString("default", { weekday: "long" }),
    shortTextDay: newDate.toLocaleString("default", { weekday: "short" }),
    hour: newDate.getHours(),
    minute: newDate.getMinutes(),
    second: newDate.getSeconds(),
    millisecond: newDate.getMilliseconds(),
    isoString: newDate.toISOString(),
  };
}

export function getDecoratedText(
  text: (
    | string
    | React.ReactNode
    | { value: string; type: "code" | "link" | "strong"; onClick?: () => any; href?: string }
  )[]
) {
  const decoratedText: React.ReactNode[] = [];
  function isDecoratedTextObject(
    item: any
  ): item is { value: string; type: "code" | "link"; onClick?: () => any; href?: string } {
    return typeof item === "object" && item !== null && "type" in item && "value" in item;
  }
  const { Text, Link } = Typography;

  text.forEach((currentText) => {
    if (typeof currentText === "string") decoratedText.push(<Text>{currentText}</Text>);
    else if (isValidElement(currentText)) {
      decoratedText.push(currentText);
    } else if (isDecoratedTextObject(currentText)) {
      const { type, value, onClick, href } = currentText;
      if (type === "link")
        decoratedText.push(
          <Link href={href} onClick={onClick}>
            {value}
          </Link>
        );
      else if (type === "code") decoratedText.push(<Text code>{value}</Text>);
      else decoratedText.push(<Text strong>{value}</Text>);
    }
  });

  return decoratedText;
}

getDecoratedText.Wrapped = (text: Parameters<typeof getDecoratedText>[0]) => {
  return <span>{getDecoratedText(text)}</span>;
};

export function getInfoButton(description: React.ReactNode, button?: ButtonProps) {
  return (
    <Popover content={description}>
      <Button icon={<MdQuestionMark />} size="small" shape="circle" {...button} />
    </Popover>
  );
}

export const allowedFiles = [
  "cfgeconomycore.xml",
  "cfgEffectArea.json",
  "cfgenvironment.xml",
  "cfgeventspawns.xml",
  "cfgignorelist.xml",
  "cfglimitsdefinition.xml",
  "cfgplayerspawnpoints.xml",
  "cfgrandompresets.xml",
  "cfgspawnabletypes.xml",
  "economy.xml",
  "events.xml",
  "globals.xml",
  "messages.xml",
  "types.xml",
  "serverDZ.cfg",
] as const;

export function getUIForData(
  data: any
): { title?: string; component: (props: FileData<any>) => React.ReactNode } | undefined {
  if ("hostname" in data) {
    return { component: ServerConfigEditor, title: "Config" };
  }
  if ("types" in data) {
    return { component: LootEditor, title: "Loot" };
  }
  if ("events" in data) {
    return { component: EventsEditor, title: "Events" };
  }
  if ("variables" in data) {
    return { component: GlobalSettingsEditor, title: "Global Settings" };
  }
  if ("messages" in data) {
    return { component: MessagesEditor, title: "Messages / Notifications" };
  }
  if ("ignore" in data) {
    return { component: IgnoreListEditor, title: "Ignore Items" };
  }
  if ("lists" in data) {
    return { component: UnderConstructionResult, title: "Limits Definition" };
  }
}

export function capitalizeString(str: string): string {
  // Replace underscores, '@', and '.' with spaces
  str = str.replace(/[_@.]/g, " ");

  // Insert spaces before uppercase letters (for camelCase and PascalCase)
  str = str.replace(/([a-z])([A-Z])/g, "$1 $2");
  str = str.replace(/([A-Z]+)([A-Z][a-z])/g, "$1 $2");

  // Replace multiple spaces with a single space and trim whitespace
  str = str.replace(/\s+/g, " ").trim();

  // Split the string into words
  const words = str.split(" ");

  // Capitalize each word
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

  // Join the words back into a single string
  return capitalizedWords.join(" ");
}
