import { XMLParser, XMLBuilder } from "fast-xml-parser";
import { atom, useAtom } from "jotai";
import { EventsXML, FileData, GlobalsXML, IgnoreListXML, MessagesXML, ServerConfigCFG, TypeXML } from "../utils/types";
import { useEffect, useState } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { getUIForData } from "../utils/utils";
import useLoader from "./loader";

const parser = new XMLParser({ ignoreAttributes: false, attributeNamePrefix: "@_" });

export const serverDataAtom = atom<{
  "serverDZ.cfg"?: FileData<ServerConfigCFG>;
  missions?: {
    [mission: string]: { [fileName: string]: FileData<any> } & {
      "types.xml"?: FileData<TypeXML>;
      "globals.xml"?: FileData<GlobalsXML>;
      "messages.xml"?: FileData<MessagesXML>;
      "cfgignorelist.xml"?: FileData<IgnoreListXML>;
      "events.xml"?: FileData<EventsXML>;
      "cfglimitsdefinition.xml"?: FileData<any>;
    };
  };
  mods?: { [modName: string]: FileData<any> };
}>({});

export default function useServerData() {
  const [serverData, setServerData] = useAtom(serverDataAtom);
  const [processedFiles, setProcessedFiles] = useState<{ toProcess: number; processed: number }>({
    processed: -1,
    toProcess: -1,
  });
  const { setLoader } = useLoader();

  useEffect(() => {
    if (processedFiles.processed < processedFiles.toProcess) setLoader(true);
    else {
      if (processedFiles.processed !== -1) {
        setProcessedFiles({
          processed: -1,
          toProcess: -1,
        });
      }
      setLoader(false);
    }
  }, [processedFiles]);

  function downloadCFGFile(data: any, filePath: string) {
    const zip = new JSZip();

    function serializeObject(obj: any, indentLevel: number = 0): string {
      let content = "";
      const indent = "    ".repeat(indentLevel);

      for (const key in obj) {
        const value = obj[key];
        if (Array.isArray(value)) {
          const arrayValues = value.map((v) => `"${v}"`).join(", ");
          content += `${indent}${key}[] = { ${arrayValues} };\n`;
        } else if (key === "template") {
          content += `class Missions\n{\n    class DayZ\n    {\n        template = "${value}";\n    };\n};\n`;
        } else if (typeof value === "string") {
          content += `${indent}${key} = "${value}";\n`;
        } else if (typeof value === "number" || typeof value === "boolean") {
          content += `${indent}${key} = ${value};\n`;
        }
      }
      return content;
    }

    const content = serializeObject(data);
    zip.file(filePath, content);

    zip.generateAsync({ type: "blob" }).then((blob) => {
      saveAs(blob, "extractMeAtServer.zip");
    });
  }

  function parseServerDZConfig(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target && typeof e.target.result === "string") {
          const content = e.target.result;
          const lines = content.split(/\r?\n/);
          const data: { [key: string]: any } = {};
          const stack: any[] = [data];
          let currentObj = data;

          const mainRegex = /^\s*([\w\d]+)\s*=\s*(.+?);\s*(?:\/\/.*)?$/;
          const arrayRegex = /^\s*([\w\d]+)\s*\[\]\s*=\s*\{\s*(.*?)\s*\};\s*(?:\/\/.*)?$/;
          const classStartRegex = /^\s*class\s+([\w\d]+)\s*\{\s*(?:\/\/.*)?$/;
          const classEndRegex = /^\s*\};\s*(?:\/\/.*)?$/;

          for (let line of lines) {
            line = line.trim();
            if (!line || line.startsWith("//")) continue; // Skip empty lines and comments

            let match = line.match(classStartRegex);
            if (match) {
              const className = match[1];
              const newObj: { [key: string]: any } = {};
              currentObj[className] = newObj;
              stack.push(currentObj);
              currentObj = newObj;
              continue;
            }

            match = line.match(classEndRegex);
            if (match) {
              currentObj = stack.pop();
              continue;
            }

            match = line.match(arrayRegex);
            if (match) {
              const key = match[1];
              const value = match[2];
              // Parse array values
              const arrayValues = value
                .split(",")
                .map((v) => v.trim().replace(/^"|"$/g, ""))
                .filter((v) => v !== "");
              currentObj[key] = arrayValues;
              continue;
            }

            match = line.match(mainRegex);
            if (match) {
              const key = match[1];
              let value: any = match[2];
              value = value.trim();
              if (value.startsWith('"') && value.endsWith('"')) {
                value = value.substring(1, value.length - 1); // Remove quotes
              } else if (value === "true" || value === "false") {
                value = value === "true";
              } else if (!isNaN(Number(value))) {
                value = Number(value);
              } else {
                // Handle other cases if necessary
              }
              currentObj[key] = value;
            }
          }
          const ui = getUIForData(data);
          setServerData((prev) => ({ ...prev, [file.name]: { ...ui, ...data, fullPath: file.webkitRelativePath } }));

          setProcessedFiles((prev) => ({ ...prev, processed: prev.processed + 1 }));
          resolve(data);
        } else {
          setProcessedFiles((prev) => ({ ...prev, processed: prev.processed + 1 }));
          reject(new Error("Failed to read file content"));
        }
      };

      reader.onerror = () => {
        setProcessedFiles((prev) => ({ ...prev, processed: prev.processed + 1 }));
        reject(new Error("Error reading file"));
      };

      reader.readAsText(file);
    });
  }

  function convertXMLToObject(file: File) {
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target && typeof e.target.result === "string") {
        const xmlData = e.target.result;
        try {
          const jsonObj = parser.parse(xmlData);
          const ui = getUIForData(jsonObj);
          if (ui) {
            const [_, modName, mission] = file.webkitRelativePath.split("/");

            if (file.webkitRelativePath.includes("mpmissions")) {
              setServerData((prev) => ({
                ...prev,
                missions: {
                  ...prev.missions,
                  [mission]: {
                    ...(prev.missions || {})[mission],
                    [file.name]: { ...ui, ...jsonObj, fullPath: file.webkitRelativePath },
                  },
                },
              }));
            } else if (file.webkitRelativePath.includes("@")) {
              setServerData((prev) => ({
                ...prev,
                mods: {
                  ...prev.mods,
                  [modName]: {
                    ...(prev.mods || {})[modName],
                    [file.name]: { ...ui, ...jsonObj, fullPath: file.webkitRelativePath },
                  },
                },
              }));
            }
          }
        } catch (error) {
          // console.log(file.name, error, xmlData);
        }
      }
      setProcessedFiles((prev) => ({ ...prev, processed: prev.processed + 1 }));
    };

    reader.readAsText(file);
  }

  function downloadObjectAsXMLFile(data: any, filePath: string, declaration?: string) {
    const jsonObj = data;
    const zip = new JSZip();

    const builder = new XMLBuilder({
      attributeNamePrefix: "@_",
      ignoreAttributes: false,
      format: true,
      suppressEmptyNode: true,
    });

    const xmlContent = builder.build(jsonObj);

    const xmlDeclaration = declaration || '<?xml version="1.0" encoding="utf-8"?>\n';
    const completeXML = xmlDeclaration + xmlContent;

    zip.file(filePath, completeXML);

    zip.generateAsync({ type: "blob" }).then((blob) => {
      saveAs(blob, "extractMeAtServer.zip");
    });
  }

  return {
    processedFiles,
    setProcessedFiles,
    serverData,
    setServerData,
    convertXMLToObject,
    downloadObjectAsXMLFile,
    parseServerDZConfig,
    downloadCFGFile,
  };
}
