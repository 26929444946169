import { useEffect, useMemo, useState } from "react";
import {
  Descriptions,
  Input,
  InputNumber,
  Switch,
  Button,
  Typography,
  Select,
  DatePicker,
  Space,
  List,
  Flex,
  theme,
  App,
} from "antd";
import useServerData from "../../../jotai/serverData";
import { DownloadOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { MdQuestionMark } from "react-icons/md";
import dayjs from "dayjs";
import { Cell, Pie, PieChart, PieProps, ResponsiveContainer } from "recharts";
import { getInfoButton } from "../../../utils/utils";
import useResponsive from "../../../hooks/useResponsive";
import { FileData, ServerConfigCFG } from "../../../utils/types";

export default function ServerConfigEditor(props: FileData<ServerConfigCFG>) {
  const { breakpointCategory } = useResponsive({
    breakpointCategories: { "3": { minWidth: 1490 }, "2": { minWidth: 1170 } },
  });
  const { downloadCFGFile } = useServerData();
  const { message } = App.useApp();
  const {
    token: { yellow7, blue2, colorText },
  } = theme.useToken();
  const serverConfigData = useMemo(() => props, [props]);
  const [configData, setConfigData] = useState<typeof serverConfigData>();

  useEffect(() => {
    if (serverConfigData) {
      setConfigData(serverConfigData);
    }
  }, [serverConfigData]);

  const handleAddMotd = () => {
    setConfigData((prev: any) => ({
      ...prev,
      motd: [...(prev.motd || []), ""],
    }));
  };

  const handleRemoveMotd = (index: number) => {
    setConfigData((prev: any) => ({
      ...prev,
      motd: prev.motd.filter((_: any, i: number) => i !== index),
    }));
  };

  const handleMotdChange = (index: number, value: any) => {
    setConfigData((prev: any) => ({
      ...prev,
      motd: prev.motd.map((msg: any, i: number) => (i === index ? value : msg)),
    }));
  };

  // Utility function to perform deep merge
  function deepMerge(target: any, source: any): any {
    const output = { ...target };
    for (const key of Object.keys(source)) {
      if (source[key] instanceof Object && key in target && target[key] instanceof Object) {
        output[key] = deepMerge(target[key], source[key]);
      } else {
        output[key] = source[key];
      }
    }
    return output;
  }

  const handleChange = (key: string, value: any) => {
    setConfigData((prev: any) => deepMerge(prev, { [key]: value }));
  };

  const handleNestedChange = (path: string[], value: any) => {
    setConfigData((prev: any) => {
      const newData = { ...prev };
      let current = newData;
      for (let i = 0; i < path.length - 1; i++) {
        const segment = path[i];
        if (!current[segment]) {
          current[segment] = {};
        } else if (typeof current[segment] !== "object") {
          current[segment] = {};
        }
        current = current[segment];
      }
      current[path[path.length - 1]] = value;
      return newData;
    });
  };

  const onFinish = () => {
    downloadCFGFile(configData, props.fullPath);
    message.success("Configuration saved and downloaded");
  };

  if (!serverConfigData) {
    return (
      <Typography.Text>
        Please upload <Typography.Text code>serverDZ.cfg</Typography.Text> file from the menu to edit the configuration.
      </Typography.Text>
    );
  }

  const getLabel = (title: string, description: string) => (
    <span>
      {title} {getInfoButton(description)}
    </span>
  );

  function getCorrectDate() {
    if (configData?.serverTime) {
      const [YYYY, MM, DD, HH, mm] = configData?.serverTime.split("/");

      return dayjs(
        `${YYYY}-${`${MM}`.length < 2 ? "0" + MM : MM}-${`${DD}`.length < 2 ? "0" + DD : DD} ${
          `${HH}`.length < 2 ? "0" + HH : HH
        }:${`${mm}`.length < 2 ? "0" + mm : mm}`,
        "YYYY-MM-DD HH:mm"
      );
    }
    return null;
  }

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel: PieProps["label"] = (props) => {
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      payload: {
        payload: { acceleration },
      },
    } = props;

    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const accelerationResult = +((24 * 60) / acceleration).toFixed(2);

    return (
      <text x={x} y={y} fill={colorText} textAnchor={x > cx ? "start" : "end"}>
        {accelerationResult < 60 ? `${accelerationResult} Minutes` : `${+(accelerationResult / 60).toFixed()} Hours`}
      </text>
    );
  };

  return (
    <>
      <Space direction="vertical">
        <Space align="start">
          <Button icon={<MdQuestionMark />} size="small" shape="circle" />
          <Typography.Text style={{ whiteSpace: "pre" }}>
            {`This is the server base configuration file.\nHere you can edit anything related to the base settings of the server.`}
          </Typography.Text>
        </Space>
        <Button type="primary" onClick={onFinish} icon={<DownloadOutlined />}>
          Save and Download
        </Button>
      </Space>
      <Descriptions column={+(breakpointCategory || 1)} bordered style={{ marginTop: 16 }}>
        {/* Existing Parameters */}
        <Descriptions.Item label={getLabel("Server Name", "Server name")} span={3}>
          <Input
            value={configData?.hostname}
            onChange={(e) => handleChange("hostname", e.target.value)}
            placeholder="EXAMPLE NAME"
          />
        </Descriptions.Item>

        <Descriptions.Item label={getLabel("Mission Template", "Mission to load on server startup")} span={3}>
          <Input
            value={configData?.template || ""}
            onChange={(e) => handleNestedChange(["class Missions", "DayZ", "template"], e.target.value)}
            placeholder="dayzOffline.chernarusplus"
          />
        </Descriptions.Item>

        <Descriptions.Item
          label={getLabel(
            "Description",
            "Description of the server. Gets displayed to users in the client server browser, max length 255 characters"
          )}
          span={3}
        >
          <Input.TextArea
            value={configData?.description}
            onChange={(e) => handleChange("description", e.target.value)}
            placeholder="Some description"
          />
        </Descriptions.Item>

        <Descriptions.Item label={getLabel("Password", "Password to connect to the server")}>
          <Input.Password
            value={configData?.password}
            onChange={(e) => handleChange("password", e.target.value)}
            placeholder=""
          />
        </Descriptions.Item>

        <Descriptions.Item label={getLabel("Admin Password", "Password to become a server admin")}>
          <Input.Password
            value={configData?.passwordAdmin}
            onChange={(e) => handleChange("passwordAdmin", e.target.value)}
            placeholder=""
          />
        </Descriptions.Item>

        <Descriptions.Item label={getLabel("Enable Whitelist", "Enable/disable whitelist (value 0-1)")}>
          <Switch
            checked={!!configData?.enableWhitelist}
            onChange={(checked) => handleChange("enableWhitelist", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        <Descriptions.Item label={getLabel("Disable Banlist", "Disables the usage of ban.txt (default: false)")}>
          <Switch
            checked={!!configData?.disableBanlist}
            onChange={(checked) => handleChange("disableBanlist", checked)}
          />
        </Descriptions.Item>

        <Descriptions.Item label={getLabel("Disable Priority List", "Disables usage of priority.txt (default: false)")}>
          <Switch
            checked={!!configData?.disablePrioritylist}
            onChange={(checked) => handleChange("disablePrioritylist", checked)}
          />
        </Descriptions.Item>

        <Descriptions.Item label={getLabel("Max Players", "Maximum amount of players")}>
          <InputNumber
            min={1}
            value={configData?.maxPlayers}
            onChange={(value) => handleChange("maxPlayers", value)}
            placeholder="60"
          />
        </Descriptions.Item>

        <Descriptions.Item
          label={getLabel("Verify Signatures", "Verifies .pbos against .bisign files. (only 2 is supported)")}
        >
          <InputNumber
            min={2}
            max={2}
            value={configData?.verifySignatures}
            onChange={(value) => handleChange("verifySignatures", value)}
            placeholder="2"
          />
        </Descriptions.Item>

        <Descriptions.Item
          label={getLabel(
            "Force Same Build",
            "When enabled, the server will allow the connection only to clients with the same .exe revision as the server (value 0-1)"
          )}
        >
          <Switch
            checked={!!configData?.forceSameBuild}
            onChange={(checked) => handleChange("forceSameBuild", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        <Descriptions.Item label={getLabel("Disable VoN", "Enable/disable voice over network (value 0-1)")}>
          <Switch
            checked={!!configData?.disableVoN}
            onChange={(checked) => handleChange("disableVoN", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        <Descriptions.Item
          label={getLabel("VoN Codec Quality", "Voice over network codec quality, the higher the better (values 0-20)")}
        >
          <InputNumber
            min={0}
            max={20}
            value={configData?.vonCodecQuality}
            onChange={(value) => handleChange("vonCodecQuality", value)}
            placeholder="20"
          />
        </Descriptions.Item>

        <Descriptions.Item
          label={getLabel("Disable 3rd Person", "Toggles the 3rd person view for players (value 0-1)")}
        >
          <Switch
            checked={!!configData?.disable3rdPerson}
            onChange={(checked) => handleChange("disable3rdPerson", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        <Descriptions.Item label={getLabel("Disable Crosshair", "Toggles the cross-hair (value 0-1)")}>
          <Switch
            checked={!!configData?.disableCrosshair}
            onChange={(checked) => handleChange("disableCrosshair", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        <Descriptions.Item
          label={getLabel(
            "Server Time",
            'Initial in-game time of the server. "SystemTime" means the local time of the machine. Another possibility is to set the time to some value in "YYYY/MM/DD/HH/MM" format, e.g., "2015/4/8/17/23".'
          )}
        >
          <DatePicker
            format="YYYY-MM-DD HH:mm"
            showTime={{ format: "HH:mm" }}
            value={getCorrectDate()}
            onChange={(e, dateStr) => handleChange("serverTime", (dateStr as string).split(/[-: ]/).join("/"))}
            placeholder='Format "YYYY/MM/DD/HH/mm"'
          />
        </Descriptions.Item>

        <Descriptions.Item
          label={getLabel(
            "Server Time Acceleration",
            "Accelerated Time - The numerical value being a multiplier (0.1-64)."
          )}
          span={1}
        >
          <Flex vertical>
            <PieChart width={300} height={175}>
              <Pie
                data={[
                  {
                    name: "Day Time",
                    value: configData?.serverTimeAcceleration,
                    acceleration: configData?.serverTimeAcceleration,
                  },
                  {
                    name: "Night Time",
                    value: configData?.serverNightTimeAcceleration,
                    acceleration:
                      (configData?.serverNightTimeAcceleration ?? 1) * (configData?.serverTimeAcceleration || 1),
                  },
                ]}
                startAngle={180}
                endAngle={0}
                innerRadius={60}
                outerRadius={80}
                dataKey="value"
                label={renderCustomizedLabel}
                labelLine={false}
              >
                {[
                  {
                    name: "Day Time",
                    value: configData?.serverTimeAcceleration,
                    acceleration: configData?.serverTimeAcceleration,
                  },
                  {
                    name: "Night Time",
                    value: configData?.serverNightTimeAcceleration,
                    acceleration:
                      (configData?.serverNightTimeAcceleration ?? 1) * (configData?.serverTimeAcceleration || 1),
                  },
                ].map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={[yellow7, blue2][index % 2]} stroke="transparent" />
                ))}
              </Pie>
            </PieChart>
          </Flex>
          <Flex justify="space-evenly" align="center">
            <Space>
              <InputNumber
                min={0.1}
                max={64}
                step={0.1}
                value={configData?.serverTimeAcceleration}
                onChange={(value) => handleChange("serverTimeAcceleration", value)}
                placeholder="1"
              />
              Day
            </Space>
            <Space>
              Night
              <InputNumber
                min={0.1}
                max={64}
                step={0.1}
                value={configData?.serverNightTimeAcceleration}
                onChange={(value) => handleChange("serverNightTimeAcceleration", value)}
                placeholder="1"
              />
            </Space>
          </Flex>
        </Descriptions.Item>

        <Descriptions.Item
          label={getLabel(
            "Server Time Persistent",
            "Persistent Time (value 0-1) - The actual server time is saved to storage, so when active, the next server start will use the saved time value."
          )}
        >
          <Switch
            checked={!!configData?.serverTimePersistent}
            onChange={(checked) => handleChange("serverTimePersistent", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        <Descriptions.Item
          label={getLabel("Guaranteed Updates", "Communication protocol used with game server (use only number 1)")}
        >
          <InputNumber
            min={1}
            max={1}
            value={configData?.guaranteedUpdates}
            onChange={(value) => handleChange("guaranteedUpdates", value)}
            placeholder="1"
          />
        </Descriptions.Item>

        {/* Additional Parameters */}
        {/* respawnTime */}
        <Descriptions.Item
          label={getLabel(
            "Respawn Time",
            "Sets the respawn delay (in seconds) before the player is able to get a new character on the server, when the previous one is dead"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.respawnTime}
            onChange={(value) => handleChange("respawnTime", value)}
            placeholder="5"
          />
        </Descriptions.Item>

        {/* motd[] is already included */}

        {/* motdInterval */}
        <Descriptions.Item
          label={getLabel(
            "MOTD Interval",
            "Time interval (in seconds) between each Message of the Day (motd[]) display"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.motdInterval}
            onChange={(value) => handleChange("motdInterval", value)}
            placeholder="1"
          />
        </Descriptions.Item>

        {/* timeStampFormat is already included */}

        {/* logAverageFps */}
        <Descriptions.Item
          label={getLabel(
            "Log Average FPS",
            "Logs the average server FPS (value in seconds), needs to have '-doLogs' launch parameter active"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.logAverageFps}
            onChange={(value) => handleChange("logAverageFps", value)}
            placeholder="1"
          />
        </Descriptions.Item>

        {/* logMemory */}
        <Descriptions.Item
          label={getLabel(
            "Log Memory",
            "Logs the server memory usage (value in seconds), needs to have the '-doLogs' launch parameter active"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.logMemory}
            onChange={(value) => handleChange("logMemory", value)}
            placeholder="1"
          />
        </Descriptions.Item>

        {/* logPlayers */}
        <Descriptions.Item
          label={getLabel(
            "Log Players",
            "Logs the count of currently connected players (value in seconds), needs to have the '-doLogs' launch parameter active"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.logPlayers}
            onChange={(value) => handleChange("logPlayers", value)}
            placeholder="1"
          />
        </Descriptions.Item>

        {/* logFile */}
        <Descriptions.Item
          label={getLabel(
            "Log File",
            "Saves the server console log to a file in the folder with the other server logs"
          )}
        >
          <Input
            value={configData?.logFile}
            onChange={(e) => handleChange("logFile", e.target.value)}
            placeholder="server_console.log"
          />
        </Descriptions.Item>

        {/* adminLogPlayerHitsOnly */}
        <Descriptions.Item
          label={getLabel(
            "Admin Log Player Hits Only",
            "1 - log player hits only / 0 - log all hits (animals/infected)"
          )}
        >
          <Switch
            checked={!!configData?.adminLogPlayerHitsOnly}
            onChange={(checked) => handleChange("adminLogPlayerHitsOnly", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* adminLogPlacement */}
        <Descriptions.Item
          label={getLabel("Admin Log Placement", "1 - log placement actions (traps, tents) / 0 - do not log")}
        >
          <Switch
            checked={!!configData?.adminLogPlacement}
            onChange={(checked) => handleChange("adminLogPlacement", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* adminLogBuildActions */}
        <Descriptions.Item
          label={getLabel(
            "Admin Log Build Actions",
            "1 - log basebuilding actions (build, dismantle, destroy) / 0 - do not log"
          )}
        >
          <Switch
            checked={!!configData?.adminLogBuildActions}
            onChange={(checked) => handleChange("adminLogBuildActions", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* adminLogPlayerList */}
        <Descriptions.Item
          label={getLabel(
            "Admin Log Player List",
            "1 - log periodic player list with position every 5 minutes / 0 - do not log"
          )}
        >
          <Switch
            checked={!!configData?.adminLogPlayerList}
            onChange={(checked) => handleChange("adminLogPlayerList", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* disableMultiAccountMitigation */}
        <Descriptions.Item
          label={getLabel(
            "Disable Multi-Account Mitigation",
            "Disables multi-account mitigation on consoles when true (default: false)"
          )}
        >
          <Switch
            checked={!!configData?.disableMultiAccountMitigation}
            onChange={(checked) => handleChange("disableMultiAccountMitigation", checked)}
          />
        </Descriptions.Item>

        {/* enableDebugMonitor */}
        <Descriptions.Item
          label={getLabel(
            "Enable Debug Monitor",
            "Shows info about the character using a debug window in a corner of the screen (value 0-1)"
          )}
        >
          <Switch
            checked={!!configData?.enableDebugMonitor}
            onChange={(checked) => handleChange("enableDebugMonitor", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* steamQueryPort */}
        <Descriptions.Item
          label={getLabel(
            "Steam Query Port",
            "Defines Steam query port, should fix the issue with server not being visible in client server browser"
          )}
        >
          <InputNumber
            min={0}
            max={65535}
            value={configData?.steamQueryPort}
            onChange={(value) => handleChange("steamQueryPort", value)}
            placeholder="2305"
          />
        </Descriptions.Item>

        {/* allowFilePatching */}
        <Descriptions.Item
          label={getLabel(
            "Allow File Patching",
            'If set to 1, it will enable connection of clients with "-filePatching" launch parameter enabled'
          )}
        >
          <Switch
            checked={!!configData?.allowFilePatching}
            onChange={(checked) => handleChange("allowFilePatching", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* simulatedPlayersBatch */}
        <Descriptions.Item
          label={getLabel(
            "Simulated Players Batch",
            "Set limit of how many players can be simulated per frame (for server performance gain)"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.simulatedPlayersBatch}
            onChange={(value) => handleChange("simulatedPlayersBatch", value)}
            placeholder="20"
          />
        </Descriptions.Item>

        {/* multithreadedReplication */}
        <Descriptions.Item
          label={getLabel(
            "Multithreaded Replication",
            "Enables multi-threaded processing of server's replication system (value 0-1)"
          )}
        >
          <Switch
            checked={!!configData?.multithreadedReplication}
            onChange={(checked) => handleChange("multithreadedReplication", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* speedhackDetection */}
        <Descriptions.Item
          label={getLabel(
            "Speedhack Detection",
            "Enable speedhack detection, values 1-10 (1 strict, 10 benevolent, can be float)"
          )}
        >
          <InputNumber
            min={1}
            max={10}
            step={0.1}
            value={configData?.speedhackDetection}
            onChange={(value) => handleChange("speedhackDetection", value)}
            placeholder="1"
          />
        </Descriptions.Item>

        {/* networkRangeClose */}
        <Descriptions.Item
          label={getLabel(
            "Network Range Close",
            "Network bubble distance for spawn of close objects with items in them (e.g., backpacks), set in meters (default: 20)"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.networkRangeClose}
            onChange={(value) => handleChange("networkRangeClose", value)}
            placeholder="20"
          />
        </Descriptions.Item>

        {/* networkRangeNear */}
        <Descriptions.Item
          label={getLabel(
            "Network Range Near",
            "Network bubble distance for spawn (despawn +10%) of near inventory items objects, set in meters (default: 150)"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.networkRangeNear}
            onChange={(value) => handleChange("networkRangeNear", value)}
            placeholder="150"
          />
        </Descriptions.Item>

        {/* networkRangeFar */}
        <Descriptions.Item
          label={getLabel(
            "Network Range Far",
            "Network bubble distance for spawn (despawn +10%) of far objects (other than inventory items), set in meters (default: 1000)"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.networkRangeFar}
            onChange={(value) => handleChange("networkRangeFar", value)}
            placeholder="1000"
          />
        </Descriptions.Item>

        {/* networkRangeDistantEffect */}
        <Descriptions.Item
          label={getLabel(
            "Network Range Distant Effect",
            "Network bubble distance for spawn of effects (currently only sound effects), set in meters (default: 4000)"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.networkRangeDistantEffect}
            onChange={(value) => handleChange("networkRangeDistantEffect", value)}
            placeholder="4000"
          />
        </Descriptions.Item>

        {/* networkObjectBatchLogSlow */}
        <Descriptions.Item
          label={getLabel(
            "Network Object Batch Log Slow",
            "Maximum time a bubble can take to iterate in seconds before it is logged to the console"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.networkObjectBatchLogSlow}
            onChange={(value) => handleChange("networkObjectBatchLogSlow", value)}
            placeholder="5"
          />
        </Descriptions.Item>

        {/* networkObjectBatchEnforceBandwidthLimits */}
        <Descriptions.Item
          label={getLabel(
            "Network Object Batch Enforce Bandwidth Limits",
            "Enables a limiter for object creation based on bandwidth statistics (value 0-1)"
          )}
        >
          <Switch
            checked={!!configData?.networkObjectBatchEnforceBandwidthLimits}
            onChange={(checked) => handleChange("networkObjectBatchEnforceBandwidthLimits", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* networkObjectBatchUseEstimatedBandwidth */}
        <Descriptions.Item
          label={getLabel(
            "Network Object Batch Use Estimated Bandwidth",
            "Switch between the method behind finding the bandwidth usage of a connection. If set to 0, it will use the total of the actual data sent since the last server frame, and if set to 1, it will use a crude estimation (value 0-1)"
          )}
        >
          <Switch
            checked={!!configData?.networkObjectBatchUseEstimatedBandwidth}
            onChange={(checked) => handleChange("networkObjectBatchUseEstimatedBandwidth", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* networkObjectBatchUseDynamicMaximumBandwidth */}
        <Descriptions.Item
          label={getLabel(
            "Network Object Batch Use Dynamic Maximum Bandwidth",
            "Determines if the bandwidth limit should be a factor of the maximum bandwidth that can be sent or a hard limit. The maximum bandwidth that can be sent fluctuates depending on demand in the system. (value 0-1)"
          )}
        >
          <Switch
            checked={!!configData?.networkObjectBatchUseDynamicMaximumBandwidth}
            onChange={(checked) => handleChange("networkObjectBatchUseDynamicMaximumBandwidth", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* networkObjectBatchBandwidthLimit */}
        <Descriptions.Item
          label={getLabel(
            "Network Object Batch Bandwidth Limit",
            "The actual limit, could be a [0,1] value or a [1,inf] value depending on networkObjectBatchUseDynamicMaximumBandwidth."
          )}
        >
          <InputNumber
            min={0}
            value={configData?.networkObjectBatchBandwidthLimit}
            onChange={(value) => handleChange("networkObjectBatchBandwidthLimit", value)}
            placeholder="0.8"
            step={0.1}
          />
        </Descriptions.Item>

        {/* networkObjectBatchCompute */}
        <Descriptions.Item
          label={getLabel(
            "Network Object Batch Compute",
            "Number of objects in the create/destroy lists that are checked in a single server frame"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.networkObjectBatchCompute}
            onChange={(value) => handleChange("networkObjectBatchCompute", value)}
            placeholder="1000"
          />
        </Descriptions.Item>

        {/* networkObjectBatchSendCreate */}
        <Descriptions.Item
          label={getLabel(
            "Network Object Batch Send Create",
            "Maximum number of objects that can be sent for creation"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.networkObjectBatchSendCreate}
            onChange={(value) => handleChange("networkObjectBatchSendCreate", value)}
            placeholder="10"
          />
        </Descriptions.Item>

        {/* networkObjectBatchSendDelete */}
        <Descriptions.Item
          label={getLabel(
            "Network Object Batch Send Delete",
            "Maximum number of objects that can be sent for deletion"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.networkObjectBatchSendDelete}
            onChange={(value) => handleChange("networkObjectBatchSendDelete", value)}
            placeholder="10"
          />
        </Descriptions.Item>

        {/* defaultVisibility */}
        <Descriptions.Item
          label={getLabel(
            "Default Visibility",
            'Highest terrain render distance on server (if higher than "viewDistance=" in DayZ client profile, clientside parameter applies)'
          )}
        >
          <InputNumber
            min={0}
            value={configData?.defaultVisibility}
            onChange={(value) => handleChange("defaultVisibility", value)}
            placeholder="1375"
          />
        </Descriptions.Item>

        {/* defaultObjectViewDistance */}
        <Descriptions.Item
          label={getLabel(
            "Default Object View Distance",
            'Highest object render distance on server (if higher than "preferredObjectViewDistance=" in DayZ client profile, clientside parameter applies)'
          )}
        >
          <InputNumber
            min={0}
            value={configData?.defaultObjectViewDistance}
            onChange={(value) => handleChange("defaultObjectViewDistance", value)}
            placeholder="1375"
          />
        </Descriptions.Item>

        {/* lightingConfig */}
        <Descriptions.Item
          label={getLabel(
            "Lighting Config",
            "0 for brighter night, 1 for darker night, 2 for Sakhal-specific lighting. If enableCfgGameplayFile is enabled, this option will be overridden by the WorldsData::lightingConfig value"
          )}
        >
          <Select
            value={configData?.lightingConfig}
            onChange={(value) => handleChange("lightingConfig", value)}
            placeholder="0"
          >
            <Select.Option value={0}>Brighter Night (0)</Select.Option>
            <Select.Option value={1}>Darker Night (1)</Select.Option>
            <Select.Option value={2}>Sakhal Lighting (2)</Select.Option>
          </Select>
        </Descriptions.Item>

        {/* disablePersonalLight */}
        <Descriptions.Item
          label={getLabel(
            "Disable Personal Light",
            "Disables personal light for all clients connected to the server (value 0-1)"
          )}
        >
          <Switch
            checked={!!configData?.disablePersonalLight}
            onChange={(checked) => handleChange("disablePersonalLight", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* disableBaseDamage */}
        <Descriptions.Item
          label={getLabel(
            "Disable Base Damage",
            "Set to 1 to disable damage/destruction of fence and watchtower (value 0-1)"
          )}
        >
          <Switch
            checked={!!configData?.disableBaseDamage}
            onChange={(checked) => handleChange("disableBaseDamage", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* disableContainerDamage */}
        <Descriptions.Item
          label={getLabel(
            "Disable Container Damage",
            "Set to 1 to disable damage/destruction of tents, barrels, wooden crate and searchest (value 0-1)"
          )}
        >
          <Switch
            checked={!!configData?.disableContainerDamage}
            onChange={(checked) => handleChange("disableContainerDamage", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* disableRespawnDialog */}
        <Descriptions.Item
          label={getLabel(
            "Disable Respawn Dialog",
            "Set to 1 to disable the respawn dialog (new characters will be spawning as random) (value 0-1)"
          )}
        >
          <Switch
            checked={!!configData?.disableRespawnDialog}
            onChange={(checked) => handleChange("disableRespawnDialog", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* pingWarning */}
        <Descriptions.Item
          label={getLabel(
            "Ping Warning",
            "Set to define the ping value from which the initial yellow ping warning is triggered (value in milliseconds)"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.pingWarning}
            onChange={(value) => handleChange("pingWarning", value)}
            placeholder="200"
          />
        </Descriptions.Item>

        {/* pingCritical */}
        <Descriptions.Item
          label={getLabel(
            "Ping Critical",
            "Set to define the ping value from which the red ping warning is triggered (value in milliseconds)"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.pingCritical}
            onChange={(value) => handleChange("pingCritical", value)}
            placeholder="250"
          />
        </Descriptions.Item>

        {/* MaxPing */}
        <Descriptions.Item
          label={getLabel(
            "Max Ping",
            "Set to define the ping value from which a player is kicked from the server (value in milliseconds)"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.MaxPing}
            onChange={(value) => handleChange("MaxPing", value)}
            placeholder="300"
          />
        </Descriptions.Item>

        {/* serverFpsWarning */}
        <Descriptions.Item
          label={getLabel(
            "Server FPS Warning",
            "Set to define the server FPS value under which the initial server FPS warning is triggered (minimum value is 11)"
          )}
        >
          <InputNumber
            min={11}
            value={configData?.serverFpsWarning}
            onChange={(value) => handleChange("serverFpsWarning", value)}
            placeholder="15"
          />
        </Descriptions.Item>

        {/* shotValidation */}
        <Descriptions.Item label={getLabel("Shot Validation", "1 enables the validation, 0 disables (value 0-1)")}>
          <Switch
            checked={!!configData?.shotValidation}
            onChange={(checked) => handleChange("shotValidation", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* clientPort */}
        <Descriptions.Item label={getLabel("Client Port", "Integer value, forces the port the clients connect with")}>
          <InputNumber
            min={0}
            max={65535}
            value={configData?.clientPort}
            onChange={(value) => handleChange("clientPort", value)}
            placeholder="2304"
          />
        </Descriptions.Item>

        {/* Example of Select component */}
        <Descriptions.Item
          label={getLabel("Timestamp Format", "Format for timestamps in the .rpt file (value Full/Short)")}
        >
          <Select
            value={configData?.timeStampFormat}
            onChange={(value) => handleChange("timeStampFormat", value)}
            placeholder="Short"
          >
            <Select.Option value="Full">Full</Select.Option>
            <Select.Option value="Short">Short</Select.Option>
          </Select>
        </Descriptions.Item>

        <Descriptions.Item
          label={getLabel(
            "Respawn Time",
            "Sets the respawn delay (in seconds) before the player is able to get a new character on the server, when the previous one is dead"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.respawnTime}
            onChange={(value) => handleChange("respawnTime", value)}
            placeholder="5"
          />
        </Descriptions.Item>

        {/* MOTD Interval */}
        <Descriptions.Item
          label={getLabel(
            "MOTD Interval",
            "Time interval (in seconds) between each Message of the Day (motd[]) display"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.motdInterval}
            onChange={(value) => handleChange("motdInterval", value)}
            placeholder="1"
          />
        </Descriptions.Item>

        {/* Log Average FPS */}
        <Descriptions.Item
          label={getLabel(
            "Log Average FPS",
            "Logs the average server FPS (value in seconds), needs to have '-doLogs' launch parameter active"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.logAverageFps}
            onChange={(value) => handleChange("logAverageFps", value)}
            placeholder="1"
          />
        </Descriptions.Item>

        {/* Log Memory */}
        <Descriptions.Item
          label={getLabel(
            "Log Memory",
            "Logs the server memory usage (value in seconds), needs to have the '-doLogs' launch parameter active"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.logMemory}
            onChange={(value) => handleChange("logMemory", value)}
            placeholder="1"
          />
        </Descriptions.Item>

        {/* Log Players */}
        <Descriptions.Item
          label={getLabel(
            "Log Players",
            "Logs the count of currently connected players (value in seconds), needs to have the '-doLogs' launch parameter active"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.logPlayers}
            onChange={(value) => handleChange("logPlayers", value)}
            placeholder="1"
          />
        </Descriptions.Item>

        {/* Log File */}
        <Descriptions.Item
          label={getLabel(
            "Log File",
            "Saves the server console log to a file in the folder with the other server logs"
          )}
        >
          <Input
            value={configData?.logFile}
            onChange={(e) => handleChange("logFile", e.target.value)}
            placeholder="server_console.log"
          />
        </Descriptions.Item>

        {/* Admin Log Player Hits Only */}
        <Descriptions.Item
          label={getLabel(
            "Admin Log Player Hits Only",
            "1 - log player hits only / 0 - log all hits (animals/infected)"
          )}
        >
          <Switch
            checked={!!configData?.adminLogPlayerHitsOnly}
            onChange={(checked) => handleChange("adminLogPlayerHitsOnly", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* Admin Log Placement */}
        <Descriptions.Item
          label={getLabel("Admin Log Placement", "1 - log placement actions (traps, tents) / 0 - do not log")}
        >
          <Switch
            checked={!!configData?.adminLogPlacement}
            onChange={(checked) => handleChange("adminLogPlacement", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* Admin Log Build Actions */}
        <Descriptions.Item
          label={getLabel(
            "Admin Log Build Actions",
            "1 - log basebuilding actions (build, dismantle, destroy) / 0 - do not log"
          )}
        >
          <Switch
            checked={!!configData?.adminLogBuildActions}
            onChange={(checked) => handleChange("adminLogBuildActions", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* Admin Log Player List */}
        <Descriptions.Item
          label={getLabel(
            "Admin Log Player List",
            "1 - log periodic player list with position every 5 minutes / 0 - do not log"
          )}
        >
          <Switch
            checked={!!configData?.adminLogPlayerList}
            onChange={(checked) => handleChange("adminLogPlayerList", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* Disable Multi-Account Mitigation */}
        <Descriptions.Item
          label={getLabel(
            "Disable Multi-Account Mitigation",
            "Disables multi-account mitigation on consoles when true (default: false)"
          )}
        >
          <Switch
            checked={!!configData?.disableMultiAccountMitigation}
            onChange={(checked) => handleChange("disableMultiAccountMitigation", checked)}
          />
        </Descriptions.Item>

        {/* Enable Debug Monitor */}
        <Descriptions.Item
          label={getLabel(
            "Enable Debug Monitor",
            "Shows info about the character using a debug window in a corner of the screen (value 0-1)"
          )}
        >
          <Switch
            checked={!!configData?.enableDebugMonitor}
            onChange={(checked) => handleChange("enableDebugMonitor", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* Steam Query Port */}
        <Descriptions.Item
          label={getLabel(
            "Steam Query Port",
            "Defines Steam query port, should fix the issue with server not being visible in client server browser"
          )}
        >
          <InputNumber
            min={0}
            max={65535}
            value={configData?.steamQueryPort}
            onChange={(value) => handleChange("steamQueryPort", value)}
            placeholder="2305"
          />
        </Descriptions.Item>

        {/* Allow File Patching */}
        <Descriptions.Item
          label={getLabel(
            "Allow File Patching",
            'If set to 1, it will enable connection of clients with "-filePatching" launch parameter enabled'
          )}
        >
          <Switch
            checked={!!configData?.allowFilePatching}
            onChange={(checked) => handleChange("allowFilePatching", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* Simulated Players Batch */}
        <Descriptions.Item
          label={getLabel(
            "Simulated Players Batch",
            "Set limit of how many players can be simulated per frame (for server performance gain)"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.simulatedPlayersBatch}
            onChange={(value) => handleChange("simulatedPlayersBatch", value)}
            placeholder="20"
          />
        </Descriptions.Item>

        {/* Multithreaded Replication */}
        <Descriptions.Item
          label={getLabel(
            "Multithreaded Replication",
            "Enables multi-threaded processing of server's replication system (value 0-1)"
          )}
        >
          <Switch
            checked={!!configData?.multithreadedReplication}
            onChange={(checked) => handleChange("multithreadedReplication", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* Speedhack Detection */}
        <Descriptions.Item
          label={getLabel(
            "Speedhack Detection",
            "Enable speedhack detection, values 1-10 (1 strict, 10 benevolent, can be float)"
          )}
        >
          <InputNumber
            min={1}
            max={10}
            step={0.1}
            value={configData?.speedhackDetection}
            onChange={(value) => handleChange("speedhackDetection", value)}
            placeholder="1"
          />
        </Descriptions.Item>

        {/* Network Range Close */}
        <Descriptions.Item
          label={getLabel(
            "Network Range Close",
            "Network bubble distance for spawn of close objects with items in them (e.g., backpacks), set in meters (default: 20)"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.networkRangeClose}
            onChange={(value) => handleChange("networkRangeClose", value)}
            placeholder="20"
          />
        </Descriptions.Item>

        {/* Network Range Near */}
        <Descriptions.Item
          label={getLabel(
            "Network Range Near",
            "Network bubble distance for spawn (despawn +10%) of near inventory items objects, set in meters (default: 150)"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.networkRangeNear}
            onChange={(value) => handleChange("networkRangeNear", value)}
            placeholder="150"
          />
        </Descriptions.Item>

        {/* Network Range Far */}
        <Descriptions.Item
          label={getLabel(
            "Network Range Far",
            "Network bubble distance for spawn (despawn +10%) of far objects (other than inventory items), set in meters (default: 1000)"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.networkRangeFar}
            onChange={(value) => handleChange("networkRangeFar", value)}
            placeholder="1000"
          />
        </Descriptions.Item>

        {/* Network Range Distant Effect */}
        <Descriptions.Item
          label={getLabel(
            "Network Range Distant Effect",
            "Network bubble distance for spawn of effects (currently only sound effects), set in meters (default: 4000)"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.networkRangeDistantEffect}
            onChange={(value) => handleChange("networkRangeDistantEffect", value)}
            placeholder="4000"
          />
        </Descriptions.Item>

        {/* Network Object Batch Log Slow */}
        <Descriptions.Item
          label={getLabel(
            "Network Object Batch Log Slow",
            "Maximum time a bubble can take to iterate in seconds before it is logged to the console"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.networkObjectBatchLogSlow}
            onChange={(value) => handleChange("networkObjectBatchLogSlow", value)}
            placeholder="5"
          />
        </Descriptions.Item>

        {/* Network Object Batch Enforce Bandwidth Limits */}
        <Descriptions.Item
          label={getLabel(
            "Network Object Batch Enforce Bandwidth Limits",
            "Enables a limiter for object creation based on bandwidth statistics (value 0-1)"
          )}
        >
          <Switch
            checked={!!configData?.networkObjectBatchEnforceBandwidthLimits}
            onChange={(checked) => handleChange("networkObjectBatchEnforceBandwidthLimits", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* Network Object Batch Use Estimated Bandwidth */}
        <Descriptions.Item
          label={getLabel(
            "Network Object Batch Use Estimated Bandwidth",
            "Switch between the method behind finding the bandwidth usage of a connection. If set to 0, it will use the total of the actual data sent since the last server frame, and if set to 1, it will use a crude estimation (value 0-1)"
          )}
        >
          <Switch
            checked={!!configData?.networkObjectBatchUseEstimatedBandwidth}
            onChange={(checked) => handleChange("networkObjectBatchUseEstimatedBandwidth", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* Network Object Batch Use Dynamic Maximum Bandwidth */}
        <Descriptions.Item
          label={getLabel(
            "Network Object Batch Use Dynamic Maximum Bandwidth",
            "Determines if the bandwidth limit should be a factor of the maximum bandwidth that can be sent or a hard limit. The maximum bandwidth that can be sent fluctuates depending on demand in the system. (value 0-1)"
          )}
        >
          <Switch
            checked={!!configData?.networkObjectBatchUseDynamicMaximumBandwidth}
            onChange={(checked) => handleChange("networkObjectBatchUseDynamicMaximumBandwidth", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* Network Object Batch Bandwidth Limit */}
        <Descriptions.Item
          label={getLabel(
            "Network Object Batch Bandwidth Limit",
            "The actual limit, could be a [0,1] value or a [1,inf] value depending on networkObjectBatchUseDynamicMaximumBandwidth."
          )}
        >
          <InputNumber
            min={0}
            value={configData?.networkObjectBatchBandwidthLimit}
            onChange={(value) => handleChange("networkObjectBatchBandwidthLimit", value)}
            placeholder="0.8"
            step={0.1}
          />
        </Descriptions.Item>

        {/* Network Object Batch Compute */}
        <Descriptions.Item
          label={getLabel(
            "Network Object Batch Compute",
            "Number of objects in the create/destroy lists that are checked in a single server frame"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.networkObjectBatchCompute}
            onChange={(value) => handleChange("networkObjectBatchCompute", value)}
            placeholder="1000"
          />
        </Descriptions.Item>

        {/* Network Object Batch Send Create */}
        <Descriptions.Item
          label={getLabel(
            "Network Object Batch Send Create",
            "Maximum number of objects that can be sent for creation"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.networkObjectBatchSendCreate}
            onChange={(value) => handleChange("networkObjectBatchSendCreate", value)}
            placeholder="10"
          />
        </Descriptions.Item>

        {/* Network Object Batch Send Delete */}
        <Descriptions.Item
          label={getLabel(
            "Network Object Batch Send Delete",
            "Maximum number of objects that can be sent for deletion"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.networkObjectBatchSendDelete}
            onChange={(value) => handleChange("networkObjectBatchSendDelete", value)}
            placeholder="10"
          />
        </Descriptions.Item>

        {/* Default Visibility */}
        <Descriptions.Item
          label={getLabel(
            "Default Visibility",
            'Highest terrain render distance on server (if higher than "viewDistance=" in DayZ client profile, clientside parameter applies)'
          )}
        >
          <InputNumber
            min={0}
            value={configData?.defaultVisibility}
            onChange={(value) => handleChange("defaultVisibility", value)}
            placeholder="1375"
          />
        </Descriptions.Item>

        {/* Default Object View Distance */}
        <Descriptions.Item
          label={getLabel(
            "Default Object View Distance",
            'Highest object render distance on server (if higher than "preferredObjectViewDistance=" in DayZ client profile, clientside parameter applies)'
          )}
        >
          <InputNumber
            min={0}
            value={configData?.defaultObjectViewDistance}
            onChange={(value) => handleChange("defaultObjectViewDistance", value)}
            placeholder="1375"
          />
        </Descriptions.Item>

        {/* Lighting Config */}
        <Descriptions.Item
          label={getLabel(
            "Lighting Config",
            "0 for brighter night, 1 for darker night, 2 for Sakhal-specific lighting. If enableCfgGameplayFile is enabled, this option will be overridden by the WorldsData::lightingConfig value"
          )}
        >
          <Select
            value={configData?.lightingConfig}
            onChange={(value) => handleChange("lightingConfig", value)}
            placeholder="0"
          >
            <Select.Option value={0}>Brighter Night (0)</Select.Option>
            <Select.Option value={1}>Darker Night (1)</Select.Option>
            <Select.Option value={2}>Sakhal Lighting (2)</Select.Option>
          </Select>
        </Descriptions.Item>

        {/* Disable Personal Light */}
        <Descriptions.Item
          label={getLabel(
            "Disable Personal Light",
            "Disables personal light for all clients connected to the server (value 0-1)"
          )}
        >
          <Switch
            checked={!!configData?.disablePersonalLight}
            onChange={(checked) => handleChange("disablePersonalLight", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* Disable Base Damage */}
        <Descriptions.Item
          label={getLabel(
            "Disable Base Damage",
            "Set to 1 to disable damage/destruction of fence and watchtower (value 0-1)"
          )}
        >
          <Switch
            checked={!!configData?.disableBaseDamage}
            onChange={(checked) => handleChange("disableBaseDamage", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* Disable Container Damage */}
        <Descriptions.Item
          label={getLabel(
            "Disable Container Damage",
            "Set to 1 to disable damage/destruction of tents, barrels, wooden crate and searchest (value 0-1)"
          )}
        >
          <Switch
            checked={!!configData?.disableContainerDamage}
            onChange={(checked) => handleChange("disableContainerDamage", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* Disable Respawn Dialog */}
        <Descriptions.Item
          label={getLabel(
            "Disable Respawn Dialog",
            "Set to 1 to disable the respawn dialog (new characters will be spawning as random) (value 0-1)"
          )}
        >
          <Switch
            checked={!!configData?.disableRespawnDialog}
            onChange={(checked) => handleChange("disableRespawnDialog", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* Ping Warning */}
        <Descriptions.Item
          label={getLabel(
            "Ping Warning",
            "Set to define the ping value from which the initial yellow ping warning is triggered (value in milliseconds)"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.pingWarning}
            onChange={(value) => handleChange("pingWarning", value)}
            placeholder="200"
          />
        </Descriptions.Item>

        {/* Ping Critical */}
        <Descriptions.Item
          label={getLabel(
            "Ping Critical",
            "Set to define the ping value from which the red ping warning is triggered (value in milliseconds)"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.pingCritical}
            onChange={(value) => handleChange("pingCritical", value)}
            placeholder="250"
          />
        </Descriptions.Item>

        {/* Max Ping */}
        <Descriptions.Item
          label={getLabel(
            "Max Ping",
            "Set to define the ping value from which a player is kicked from the server (value in milliseconds)"
          )}
        >
          <InputNumber
            min={0}
            value={configData?.MaxPing}
            onChange={(value) => handleChange("MaxPing", value)}
            placeholder="300"
          />
        </Descriptions.Item>

        {/* Server FPS Warning */}
        <Descriptions.Item
          label={getLabel(
            "Server FPS Warning",
            "Set to define the server FPS value under which the initial server FPS warning is triggered (minimum value is 11)"
          )}
        >
          <InputNumber
            min={11}
            value={configData?.serverFpsWarning}
            onChange={(value) => handleChange("serverFpsWarning", value)}
            placeholder="15"
          />
        </Descriptions.Item>

        {/* Shot Validation */}
        <Descriptions.Item label={getLabel("Shot Validation", "1 enables the validation, 0 disables (value 0-1)")}>
          <Switch
            checked={!!configData?.shotValidation}
            onChange={(checked) => handleChange("shotValidation", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        {/* Client Port */}
        <Descriptions.Item label={getLabel("Client Port", "Integer value, forces the port the clients connect with")}>
          <InputNumber
            min={0}
            max={65535}
            value={configData?.clientPort}
            onChange={(value) => handleChange("clientPort", value)}
            placeholder="2304"
          />
        </Descriptions.Item>

        {/* Login Queue Concurrent Players */}
        <Descriptions.Item
          label={getLabel(
            "Login Queue Concurrent Players",
            "The number of players concurrently processed during the login process."
          )}
        >
          <InputNumber
            min={1}
            value={configData?.loginQueueConcurrentPlayers}
            onChange={(value) => handleChange("loginQueueConcurrentPlayers", value)}
            placeholder="5"
          />
        </Descriptions.Item>

        {/* Login Queue Max Players */}
        <Descriptions.Item
          label={getLabel("Login Queue Max Players", "The maximum number of players that can wait in the login queue.")}
        >
          <InputNumber
            min={1}
            value={configData?.loginQueueMaxPlayers}
            onChange={(value) => handleChange("loginQueueMaxPlayers", value)}
            placeholder="500"
          />
        </Descriptions.Item>

        {/* Instance ID */}
        <Descriptions.Item
          label={getLabel(
            "Instance ID",
            "DayZ server instance ID to identify the number of instances per box and their storage folders with persistence files."
          )}
        >
          <InputNumber
            min={1}
            value={configData?.instanceId}
            onChange={(value) => handleChange("instanceId", value)}
            placeholder="1"
          />
        </Descriptions.Item>

        {/* Storage Auto Fix */}
        <Descriptions.Item
          label={getLabel(
            "Storage Auto Fix",
            "Checks if the persistence files are corrupted and replaces corrupted ones with empty ones (value 0-1)."
          )}
        >
          <Switch
            checked={!!configData?.storageAutoFix}
            onChange={(checked) => handleChange("storageAutoFix", checked ? 1 : 0)}
          />
        </Descriptions.Item>

        <Descriptions.Item
          label={getLabel("Message of the Day (MOTD)", "Messages displayed in the in-game chat.")}
          span={3}
        >
          <List
            dataSource={configData?.motd || []}
            renderItem={(item: any, index) => (
              <List.Item actions={[<MinusCircleOutlined key="delete" onClick={() => handleRemoveMotd(index)} />]}>
                <Input
                  value={item}
                  onChange={(e) => handleMotdChange(index, e.target.value)}
                  placeholder={`MOTD ${index + 1}`}
                />
              </List.Item>
            )}
            footer={
              <Button type="dashed" onClick={handleAddMotd} block icon={<PlusOutlined />}>
                Add MOTD
              </Button>
            }
          />
        </Descriptions.Item>
      </Descriptions>
    </>
  );
}
