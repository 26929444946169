import React, { useEffect, useMemo, useState } from "react";
import useServerData from "../../../jotai/serverData";
import { Button, InputNumber, Mentions, Space, Switch, Table, TableColumnType, Tag, Typography, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { MdQuestionMark } from "react-icons/md";
import { FaPlus, FaTrash } from "react-icons/fa6";
import { getInfoButton } from "../../../utils/utils";
import { useParams } from "react-router-dom";
import { FileData, MessagesXML } from "../../../utils/types";

export type MessageType = {
  key: string;
  delay: number;
  repeat: number;
  deadline: number;
  onconnect: number;
  countdown: number;
  onrepeat: number;
  shutdown: number;
  text: string;
};

export default function MessagesEditor(props: FileData<MessagesXML>) {
  const { downloadObjectAsXMLFile } = useServerData();
  const { type = "", modORMission: mission = "", fileName = "" } = useParams();
  const {
    messages: { message: initialMessages },
  } = props;

  const [messages, setMessages] = useState<MessageType[]>(
    [initialMessages].flat().map((msg, index) => ({ ...msg, key: `${index}` }))
  );

  useEffect(() => {
    setMessages([initialMessages].flat().map((msg, index) => ({ ...msg, key: `${index}` })));
  }, [initialMessages]);

  // Columns configuration
  const columnsData = useMemo(
    (): (Omit<TableColumnType<MessageType>, "title"> & {
      description?: React.ReactNode;
      title: any;
    })[] => [
      {
        title: "Message",
        dataIndex: "text",
        render: (value, record) => (
          <Mentions
            autoSize
            styles={{ textarea: { minHeight: 100 } }}
            value={value}
            onChange={(e) => handleEdit(record.key, "text", e)}
            prefix={["#"]}
            options={["name", "port", "tmin"].map((value) => ({
              value,
              label: value,
            }))}
          />
        ),
        description: (
          <Typography style={{ whiteSpace: "pre" }}>
            {`The message to display.\nProperties available: `}
            {["name", "port", "tmin"].map((v) => (
              <Tag key={v}>#{v}</Tag>
            ))}
          </Typography>
        ),
      },
      {
        title: "Delay",
        dataIndex: "delay",
        render: (value, record) => (
          <InputNumber value={+value || 0} onChange={(e) => handleEdit(record.key, "delay", e)} />
        ),
        description: (
          <Typography.Text style={{ whiteSpace: "pre" }}>
            {`Applied when the server message has the onconnect flag set.\nThis value indicates how many minutes it will take\nbefore the message is sent to the player.\n0 = when the player connects.`}
          </Typography.Text>
        ),
      },
      {
        title: "Repeat",
        dataIndex: "repeat",
        render: (value, record) => (
          <InputNumber value={+value || 0} onChange={(e) => handleEdit(record.key, "repeat", e)} />
        ),
        description: (
          <Typography.Text style={{ whiteSpace: "pre" }}>
            {`This value controls the frequency of the message repetition.`}
          </Typography.Text>
        ),
      },
      {
        title: "Shutdown",
        dataIndex: "shutdown",
        render: (value, record) => (
          <Switch checked={!!+value} onChange={(checked) => handleEdit(record.key, "shutdown", +checked)} />
        ),
        description: (
          <Typography.Text style={{ whiteSpace: "pre" }}>
            {`Indicates that the server will shutdown after the countdown reaches zero.\nIf not set, this is ignored.`}
          </Typography.Text>
        ),
      },
      {
        title: "On Connect",
        dataIndex: "onconnect",
        render: (value, record) => (
          <Switch checked={!!+value} onChange={(checked) => handleEdit(record.key, "onconnect", +checked)} />
        ),
        description: (
          <Typography.Text style={{ whiteSpace: "pre" }}>
            {`Indicates that the server message will be sent once after a player connects to the server.`}
          </Typography.Text>
        ),
      },
      {
        title: "Deadline",
        dataIndex: "deadline",
        render: (value, record) => (
          <InputNumber value={+value || 0} onChange={(e) => handleEdit(record.key, "deadline", e)} />
        ),
        description: (
          <Typography.Text style={{ whiteSpace: "pre" }}>
            {`Applied when the server message has the Countdown flag set. Indicates how long it takes the countdown before it reaches zero.`}
          </Typography.Text>
        ),
      },
      {
        title: "Countdown",
        dataIndex: "countdown",
        render: (value, record) => (
          <Switch checked={!!+value} onChange={(checked) => handleEdit(record.key, "countdown", +checked)} />
        ),
        description: (
          <Typography.Text style={{ whiteSpace: "pre" }}>
            {`Indicates that the server message will be sent to all players in a countdown manner.\nThe message will be sent 90 minutes before the deadline is met, then 60 minutes, 45, 30, 20, 15, 10, 5, 2 and finally 1 minute before the deadline is met.`}
          </Typography.Text>
        ),
      },
      {
        title: "Actions",
        dataIndex: "actions",
        render: (_, record) =>
          messages.length >= 1 ? (
            <Button icon={<FaTrash />} danger onClick={() => handleDelete(record.key)}>
              Delete
            </Button>
          ) : null,
      },
    ],
    [messages]
  );

  // Function to handle editing
  const handleEdit = (key: string, field: string, value: any) => {
    setMessages((prev) => prev.map((item) => (item.key === key ? { ...item, [field]: value } : item)));
  };

  // Add a new message
  const handleAddMessage = () => {
    const newMessage: MessageType = {
      key: `${messages.length + 1}`,
      text: "",
      delay: 0,
      repeat: 0,
      shutdown: 0,
      onconnect: 0,
      deadline: 0,
      countdown: 0,
      onrepeat: 0,
    };
    setMessages((prev) => [...prev, newMessage]);
  };

  // Handle delete message
  const handleDelete = (key: string) => {
    setMessages((prev) => prev.filter((item) => item.key !== key));
  };

  // Download updated XML
  const handleDownload = () => {
    const updatedMessages = { messages: { message: messages.map(({ key, ...messageData }) => messageData) } };
    downloadObjectAsXMLFile(updatedMessages, props.fullPath);
    message.success("Messages downloaded.");
  };

  return (
    <>
      <Space align="start">
        <Button icon={<MdQuestionMark />} size="small" shape="circle" />
        <Typography.Text style={{ whiteSpace: "pre" }}>
          {`The DayZ server mesages.xml file is used to define the various text messages and notifications displayed to players in the game.\nIt allows server administrators to customize the messaging experience for players and provide relevant information in a clear and concise manner.`}
        </Typography.Text>
      </Space>
      <Table
        columns={columnsData.map(({ title, description, ...otherProps }) => ({
          title: (
            <Space>
              {title}
              {description && getInfoButton(description)}
            </Space>
          ),
          ...otherProps,
        }))}
        title={() => (
          <Space>
            <Button htmlType="submit" icon={<FaPlus />} style={{ marginBottom: 16 }} onClick={handleAddMessage}>
              Add Message
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              icon={<DownloadOutlined />}
              style={{ marginBottom: 16 }}
              onClick={handleDownload}
            >
              Download
            </Button>
          </Space>
        )}
        virtual
        dataSource={messages}
        scroll={{ y: 550, x: 1500 }}
      />
    </>
  );
}
