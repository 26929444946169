import { Result } from "antd";
import React from "react";
import { IoIosConstruct } from "react-icons/io";

export default function UnderConstructionResult() {
  return (
    <Result
      status="info"
      icon={<IoIosConstruct style={{ fontSize: 100 }} />}
      title="Under Construction"
      subTitle="Coming Soon! Stay tuned..."
    />
  );
}
