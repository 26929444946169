import { Modal, Typography } from "antd";
import useLoader from "../jotai/loader";

export default function ScreenBlockLoader() {
  const { loader } = useLoader();

  return (
    <Modal closable={false} open={loader} footer={null}>
      <Typography.Title style={{ margin: 0 }}>Loading...</Typography.Title>
    </Modal>
  );
}
