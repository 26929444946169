import { Menu, MenuProps, Popover, Typography } from "antd";
import React, { useMemo } from "react";
import useServerData from "../jotai/serverData";
import { useLocation, useNavigate } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { BsPlugin } from "react-icons/bs";
import { capitalizeString } from "../utils/utils";

export default function CustomMenu() {
  const { serverData } = useServerData();
  const navigate = useNavigate();
  const missions = useMemo(() => serverData.missions || {}, [serverData]);
  const mods = useMemo(() => serverData.mods || {}, [serverData]);
  const currentTemplate = useMemo(() => serverData["serverDZ.cfg"]?.template, [serverData]);
  const location = useLocation();

  function getMenuItem({
    key,
    label,
    icon,
    disabled,
    children,
  }: {
    key: string;
    label: React.ReactNode;
    icon?: React.ReactNode;
    disabled?: boolean;
    children?: ReturnType<typeof getMenuItem>[];
  }): NonNullable<MenuProps["items"]>[number] {
    return {
      disabled,
      key,
      label,
      icon,
      children,
    };
  }

  function getLabelWithPopover(label: string) {
    return <Popover content={label}>{label}</Popover>;
  }

  const menuItems = useMemo(() => {
    const items = [];
    const configItem = serverData["serverDZ.cfg"];
    configItem && items.push(getMenuItem({ label: configItem?.title, key: "serverDZ.cfg" }));

    Object.keys(missions).length &&
      items.push(
        getMenuItem({
          key: "mpmissions",
          label: "Missions",
          children: Object.keys(missions).map((mission) =>
            getMenuItem({
              label: getLabelWithPopover(capitalizeString(mission.split(".")[1])),
              key: `mpmissions/${mission}`,
              icon: currentTemplate === mission && <FaCheckCircle />,
              children: Object.keys(missions[mission]).map((fileName) =>
                getMenuItem({
                  label: getLabelWithPopover(missions[mission][fileName].title),
                  key: `mpmissions/${mission}/${fileName}`,
                })
              ),
            })
          ),
        })
      );

    Object.keys(mods).length &&
      items.push(
        getMenuItem({
          key: "mods",
          icon: <BsPlugin />,
          label: "Mods",
          children: Object.keys(mods).map((mod) =>
            getMenuItem({
              label: getLabelWithPopover(capitalizeString(mod)),
              key: `mods/${mod}`,
              children: Object.keys(mods[mod]).map((fileName) =>
                getMenuItem({
                  label: getLabelWithPopover(capitalizeString(fileName.split(".")[0])),
                  key: `mods/${mod}/${fileName}`,
                })
              ),
            })
          ),
        })
      );

    return items;
  }, [serverData, missions, currentTemplate, mods]);

  return menuItems.length ? (
    <Menu
      onSelect={({ key }) => navigate(`/${key}`)}
      selectedKeys={[decodeURI(location.pathname.substring(1))]}
      mode="inline"
      style={{ borderInlineEnd: "none" }}
      items={menuItems}
    />
  ) : (
    <Typography.Text type="secondary">Upload server files</Typography.Text>
  );
}
