import { atom, useAtom } from "jotai";
import StorageManager from "../managers/StorageManager";
import { useEffect } from "react";

export const darkModeAtom = atom(StorageManager.get("darkMode") || false);

export default function useDarkMode() {
  const [darkMode, setDarkMode] = useAtom(darkModeAtom);

  function switchDarkModeState() {
    setDarkMode(!darkMode);
  }

  useEffect(() => {
    StorageManager.set("darkMode", darkMode);
  }, [darkMode]);

  return { darkMode, setDarkMode, switchDarkModeState };
}
