import { Button, Input, InputNumber, InputProps, Slider, Space, theme } from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";
import React, { useEffect, useState } from "react";
import { MdSearch } from "react-icons/md";

type CustomDropdownProps = {
  children?: React.ReactNode;
  onConfirm?: (props: FilterDropdownProps) => any;
  onReset?: (props: FilterDropdownProps) => any;
} & FilterDropdownProps;

function CustomDropdown({ children, onConfirm, onReset, ...dropdownProps }: CustomDropdownProps) {
  const { confirm, clearFilters } = dropdownProps;
  const {
    token: { padding },
  } = theme.useToken();

  return (
    <div style={{ padding }} onKeyDown={(e) => e.stopPropagation()}>
      <Space direction="vertical">
        {children}
        <Space>
          <Button
            type="primary"
            onClick={() => onConfirm && onConfirm(dropdownProps)}
            icon={<MdSearch />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              confirm();
              onReset && onReset(dropdownProps);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </Space>
    </div>
  );
}

const FilterDropdown = {
  Numbers: ({ min, max, onReset, ...dropdownProps }: CustomDropdownProps & { min?: number; max?: number }) => {
    const [range, setRange] = useState<[number, number]>([Math.ceil(min || 0), Math.ceil(max || 0)]);

    function CustomOnReset() {
      setRange([Math.ceil(min || 0), Math.ceil(max || 0)]);
      onReset && onReset(dropdownProps);
    }

    useEffect(() => {
      dropdownProps.setSelectedKeys(range);
    }, [range]);

    return (
      <CustomDropdown onReset={CustomOnReset} {...dropdownProps}>
        <Space>
          <InputNumber
            style={{ width: 75 }}
            min={0}
            max={range[1] - 1}
            onChange={(value) => setRange((prev) => [value || 0, prev[1]])}
            size="small"
            value={range[0]}
          />
          <Slider
            tooltip={{ formatter: null }}
            style={{ width: 100 }}
            defaultValue={range}
            min={0}
            max={(max || 0) * 2 || 100}
            range
            onChange={(value) => setRange([value[0], value[1]])}
          />
          <InputNumber
            style={{ width: 75 }}
            size="small"
            value={range[1]}
            min={range[0] + 1}
            onChange={(value) => setRange((prev) => [prev[0], value || 0])}
          />
        </Space>
      </CustomDropdown>
    );
  },
  Strings: ({
    placeholder,
    value,
    onConfirm,
    ...dropdownProps
  }: CustomDropdownProps & { placeholder?: string; value?: InputProps["value"] }) => (
    <CustomDropdown onConfirm={onConfirm} {...dropdownProps}>
      <Input
        placeholder={placeholder}
        value={value}
        onChange={(e) => dropdownProps.setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => onConfirm && onConfirm(dropdownProps)}
      />
    </CustomDropdown>
  ),
};

export default FilterDropdown;
