import { Button, Flex, InputNumber, Space, Switch, Tag, theme, Typography } from "antd";
import { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { MdNumbers, MdPercent } from "react-icons/md";

type IncreaserProps = {
  title: string;
  onChange: (amount: number, isPercentage: boolean, range: { minKey: boolean; maxKey: boolean } | null) => any;
  range?: { minKey: string; maxKey: string };
};

export default function Increaser({ title, onChange, range }: IncreaserProps) {
  const [byPercentage, setByPercentage] = useState(true);
  const [amount, setAmount] = useState(0);
  const [rangeChecked, setRangeChecked] = useState<{ minKey: boolean; maxKey: boolean } | null>(
    range ? { minKey: true, maxKey: true } : null
  );
  const {
    token: { borderRadius, lineWidth, colorBorder },
  } = theme.useToken();

  return (
    <Space>
      <Typography.Text ellipsis>{title}</Typography.Text>
      <Space.Compact direction="vertical">
        <Button
          onClick={() => onChange(amount, byPercentage, rangeChecked)}
          style={{ height: 17.5 }}
          size="small"
          icon={<FaAngleUp />}
          disabled={byPercentage && amount < 1 ? true : false}
        />
        <Button
          onClick={() => onChange(-amount, byPercentage, rangeChecked)}
          style={{ height: 17.5 }}
          size="small"
          icon={<FaAngleDown />}
          disabled={byPercentage && amount < 1 ? true : false}
        />
      </Space.Compact>
      <Flex vertical style={{ gap: 0, minWidth: 75 }}>
        <InputNumber
          style={{
            width: "100%",
            borderBottomLeftRadius: range ? 0 : undefined,
            borderBottomRightRadius: range ? 0 : undefined,
          }}
          onChange={(v) => setAmount(v || 0)}
          min={byPercentage ? 1 : 0}
          defaultValue={0}
          suffix={byPercentage ? <MdPercent /> : <MdNumbers />}
        />
        {range && (
          <Space.Compact style={{ width: "100%", padding: 0, margin: 0 }}>
            <Tag.CheckableTag
              onClick={() => setRangeChecked((prev) => (prev ? { ...prev, minKey: !prev.minKey } : null))}
              checked={!!rangeChecked?.minKey}
              style={{
                borderRadius: 0,
                borderBottomLeftRadius: borderRadius,
                borderColor: colorBorder,
                borderWidth: lineWidth,
                borderTop: "none",
                width: "100%",
                margin: 0,
              }}
            >
              {range.minKey}
            </Tag.CheckableTag>
            <Tag.CheckableTag
              onClick={() => setRangeChecked((prev) => (prev ? { ...prev, maxKey: !prev.maxKey } : null))}
              checked={!!rangeChecked?.maxKey}
              style={{
                borderRadius: 0,
                borderBottomRightRadius: borderRadius,
                borderColor: colorBorder,
                borderWidth: lineWidth,
                borderTop: "none",
                width: "100%",
                margin: 0,
              }}
            >
              {range.maxKey}
            </Tag.CheckableTag>
          </Space.Compact>
        )}
      </Flex>
      <Switch
        defaultChecked
        onChange={setByPercentage}
        checkedChildren={<MdPercent />}
        unCheckedChildren={<MdNumbers />}
      />
    </Space>
  );
}
