import { Switch } from "antd";
import React from "react";
import { MdDarkMode, MdLightMode } from "react-icons/md";
import useDarkMode from "../jotai/darkMode";

export default function DarkModeSwitch() {
  const { darkMode, setDarkMode } = useDarkMode();
  return (
    <Switch
      checked={darkMode}
      onChange={(state) => setDarkMode(state)}
      checkedChildren={<MdDarkMode />}
      unCheckedChildren={<MdLightMode />}
    />
  );
}
