import { StorageTypes } from "../utils/types";
import CryptoManager from "./CryptoManager";

export default class StorageManager {
  static get<ReturnType extends unknown = any>(key: StorageTypes): ReturnType | undefined {
    try {
      const data = localStorage.getItem(key);
      return data ? JSON.parse(CryptoManager.decrypt(data)) : undefined;
    } catch (error) {
      return undefined;
    }
  }

  static set(key: StorageTypes, data: any): boolean {
    try {
      localStorage.setItem(key, CryptoManager.encrypt(JSON.stringify(data)));
      return true;
    } catch (error) {
      return false;
    }
  }
}
