import React, { useMemo } from "react";
import { getDecoratedText } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import useServerData from "../../../jotai/serverData";
import { Flex, List, Space, theme, Typography } from "antd";
import { FaNewspaper } from "react-icons/fa6";

export default function News() {
  const { serverData } = useServerData();
  const navigate = useNavigate();
  const activeMission = useMemo(() => serverData["serverDZ.cfg"]?.template, [serverData]);
  const {
    token: { padding },
  } = theme.useToken();

  const updatedList = useMemo(() => {
    function redirectToMenuItem(path: string) {
      const [type, fileName] = path.split("/");
      navigate(`/${type}${type !== "serverDZ.cfg" ? `/${activeMission}/${fileName}` : ""}`);
    }

    const links: {
      [key in ["Loot", "Messages", "Config", "Global Settings", "Ignore List"][number]]: Parameters<
        typeof getDecoratedText
      >[0][number];
    } = {
      Loot: {
        value: "Loot",
        type: "link",
        onClick: () => redirectToMenuItem("missions/types.xml"),
      },
      Messages: {
        value: "Messages",
        type: "link",
        onClick: () => redirectToMenuItem("missions/messages.xml"),
      },
      Config: {
        value: "Server Configuration",
        type: "link",
        onClick: () => redirectToMenuItem("serverDZ.cfg"),
      },
      "Global Settings": {
        value: "Global Settings",
        type: "link",
        onClick: () => redirectToMenuItem("missions/globals.xml"),
      },
      "Ignore List": {
        value: "Ignore List",
        type: "link",
        onClick: () => redirectToMenuItem("missions/cfgignorelist.xml"),
      },
    };

    return [
      {
        title: "Loot Editor",
        description: getDecoratedText.Wrapped([
          links.Loot,
          " Editor Was added!",
          <br />,
          "Now able to customize your loot in the server",
        ]),
        date: "10/20/2024",
      },
      {
        title: "Config",
        description: getDecoratedText.Wrapped([
          links.Messages,
          " Editor Was added!",
          <br />,
          "Now able to customize your messages / notifications in the server",
        ]),
        date: "10/20/2024",
      },
      {
        title: "Messages",
        description: getDecoratedText.Wrapped([
          links.Config,
          " Editor Was added!",
          <br />,
          "Now able to customize your config file easily",
        ]),
        date: "10/20/2024",
      },
      {
        title: "Global Settings",
        description: getDecoratedText.Wrapped([
          links["Global Settings"],
          " Editor Was added!",
          <br />,
          "You can now edit the global settings of the server",
        ]),
        date: "10/21/2024",
      },
      {
        title: "Ignore List",
        description: getDecoratedText.Wrapped([
          links["Ignore List"],
          " Was Added!",
          <br />,
          "Now you can add all of your items to not spawn in the server.",
          <br />,
          <br />,
          "Also, if an item now is on the ",
          links["Ignore List"],
          ", You cannot edit it in the ",
          links.Loot,
          " Editor",
        ]),
        date: "10/25/2024",
      },
      {
        title: "Config",
        description: getDecoratedText.Wrapped([
          "In the ",
          links.Config,
          " the time acceleration UI was updated to better show",
        ]),
        date: "10/25/2024",
      },
      {
        title: "Config",
        description: "Added support of all missions and mods data editing!!!",
        date: "10/30/2024",
      },
    ];
  }, [activeMission, navigate]);

  return (
    <List
      header={
        <Typography.Title level={3} style={{ margin: 0 }}>
          <Flex gap={padding} align="center">
            <FaNewspaper />
            NEWS
          </Flex>
        </Typography.Title>
      }
      style={{ height: "100%", width: "100%", overflowY: "auto" }}
      bordered
      dataSource={updatedList.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())}
      renderItem={(item, i) => (
        <List.Item style={{ width: "100%" }}>
          <Space align="start" split=":">
            <Typography.Text type="success" strong>
              NEW! <Typography.Text type="secondary">{new Date(item.date).toDateString()}</Typography.Text>
            </Typography.Text>
            {item.description}
          </Space>
        </List.Item>
      )}
    />
  );
}
